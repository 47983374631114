import {
  CloseCircleOutlined,
  EditFilled,
  RobotOutlined,
} from '@ant-design/icons';
import { IFile } from '@shared/files';
import { formatDate } from '@web/common/formatDate';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Small, Text, TypographyCss } from '@web/components/typography';
import { EditFileModal } from '@web/meetings/agenda_items/EditFileModal';
import { Tooltip } from 'antd';
import { last } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { FileIcon } from '../topics/FileIcon';

export const FileButton: React.FC<{
  file: IFile;
  onRemove?: () => void;
  onChange?: () => void;
  onIndex?: () => void;
}> = ({ file, onRemove, onChange, onIndex }) => {
  const [editing, setEditing] = React.useState(false);
  const extension = last(file.name.split('.'));

  const handleRemoveClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onRemove();
  };
  const handleEditClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setEditing(true);
  };
  const handleIndexClicked = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onIndex();
  };

  return (
    <FileButtonLink
      href={`/api/files/${file.token}/${file.name}`}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Row gap={12} style={{ padding: '6px 12px 6px 0' }}>
        <FileIcon extension={extension} size={28} />
        <Column>
          <Text>{file.name}</Text>
          <Small
            style={{
              color: '#888',
              fontStyle: file.description ? undefined : 'italic',
            }}
          >
            {file.description ?? 'No description'}
          </Small>
        </Column>
        <GrowingSpacer />
        {onIndex && (
          <>
            {file.indexedDate && (
              <Small>Indexed {formatDate(file.indexedDate)}</Small>
            )}
            <Tooltip title="Index attachment">
              <RobotOutlined
                style={{ fontSize: 18 }}
                onClick={handleIndexClicked}
              />
            </Tooltip>
          </>
        )}
        {onChange && (
          <Tooltip title="Edit attachment">
            <EditFilled style={{ fontSize: 18 }} onClick={handleEditClicked} />
          </Tooltip>
        )}
        {onRemove && (
          <Tooltip title="Remove attachment">
            <CloseCircleOutlined
              style={{ fontSize: 18 }}
              onClick={handleRemoveClicked}
            />
          </Tooltip>
        )}
        {editing && (
          <EditFileModal
            file={file}
            onClose={() => {
              setEditing(false);
            }}
            onSave={() => {
              setEditing(false);
              onChange();
            }}
          />
        )}
      </Row>
    </FileButtonLink>
  );
};
const FileButtonLink = styled.a`
  ${TypographyCss.Small}
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 28px;

  .hover-show {
    display: none;
  }

  &:hover {
    border-radius: 6px;
    background: #ec497f1a;

    .hover-show {
      display: block;
    }
  }
`;
