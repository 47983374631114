import { IMeeting, MeetingState } from '@shared/meetings';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { PrimaryButton } from '@web/components/Button';
import { DateDivider } from '@web/components/DateDivider';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header1, Text } from '@web/components/typography';
import { Skeleton } from 'antd';
import { reverse, sortBy } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { MeetingListItem } from './MeetingListItem';

export const MeetingsPage: React.FC = () => {
  const { data: meetings } = useApi<IMeeting[]>('/meetings');
  if (!meetings) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const closedMeetings = reverse(
    sortBy(
      meetings.filter((meeting) => meeting.state === MeetingState.CLOSED),
      'date.date',
    ),
  );

  const openMeetings = sortBy(
    meetings.filter((meeting) => meeting.state === MeetingState.OPEN),
    'date.date',
  );

  return (
    <PageContent>
      <Column>
        <Row gap={12}>
          <Header1 style={{ whiteSpace: 'nowrap' }}>Board Meetings</Header1>
          <GrowingSpacer />
          <Link to={`/meetings/new`}>
            <PrimaryButton>Add Meeting</PrimaryButton>
          </Link>
        </Row>
        <DateDivider>Upcoming</DateDivider>
        <Column gap={12}>
          {openMeetings.length > 0 ? (
            openMeetings.map((meeting) => (
              <MeetingListItem meeting={meeting} key={meeting.token} />
            ))
          ) : (
            <Text>No meetings scheduled</Text>
          )}
        </Column>
        {closedMeetings.length > 0 && (
          <>
            <DateDivider>Past</DateDivider>
            <Column gap={12}>
              {closedMeetings.map((meeting) => (
                <MeetingListItem meeting={meeting} key={meeting.token} />
              ))}
            </Column>
          </>
        )}
      </Column>
    </PageContent>
  );
};
