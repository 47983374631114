export const mapByToken = <ItemType extends { token: TokenType }, TokenType>(
  items: ItemType[] = [],
  existingMap?: Map<TokenType, ItemType>,
) => {
  const itemMap = new Map<TokenType, ItemType>(existingMap);
  for (const item of items) {
    itemMap.set(item.token, item);
  }
  return itemMap;
};

export const mapByProp = <PropertyType, ItemType>(
  items: ItemType[] = [],
  propertyName: string,
) => {
  const itemMap = new Map<PropertyType, ItemType>();
  for (const item of items) {
    itemMap.set(item[propertyName], item);
  }
  return itemMap;
};
