import { ITopic } from '@shared/topics';
import { post } from '@web/common/api';
import { Spacer } from '@web/components/layout';
import { Modal, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';

interface Props {
  onClose: () => void;
  onTopicClosed: () => void;
  topic?: ITopic;
}

export const CloseTopicModal: React.FC<Props> = ({
  onClose,
  onTopicClosed,
  topic,
}) => {
  const [commentText, setCommentText] = React.useState('');

  const handleCloseTopic = async () => {
    try {
      await post(`/topics/${topic.token}/close`, { comment: commentText });
      setCommentText('');
      void message.success('Success');
      onTopicClosed();
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Modal
      title="Close Topic"
      open={true}
      onOk={handleCloseTopic}
      onCancel={onClose}
      width={500}
      okText="Close"
    >
      <Spacer size={20} />
      <TextArea
        value={commentText}
        onChange={(e) => {
          setCommentText(e.target.value);
        }}
        placeholder="Add a comment here..."
      />
      <Spacer size={20} />
    </Modal>
  );
};
