import { HomeOutlined } from '@ant-design/icons';
import { useAuth } from '@web/auth/useAuth';
import { GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Text, TypographyCss } from '@web/components/typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Topbar: React.FC = () => {
  const { organization, logout } = useAuth();

  return (
    <TopbarContainer>
      <Link to="/home">
        <Row gap={12}>
          <HomeOutlined style={{ fontSize: 16, color: 'white' }} />
          <Text style={{ fontSize: 16 }}>{organization.name}</Text>
        </Row>
      </Link>
      <Spacer />
      <TopbarLink to="/meetings">Meetings</TopbarLink>
      <TopbarLink to="/action-items">Action Items</TopbarLink>
      <TopbarLink to="/admin/files">Files</TopbarLink>
      <TopbarLink to="/admin/agenda-items">Agenda Items</TopbarLink>
      <GrowingSpacer />
      <TopbarButton onClick={logout}>Logout</TopbarButton>
    </TopbarContainer>
  );
};
const TopbarContainer = styled(Row)`
  background-color: #333;
  height: 50px;
  gap: 6px;
  color: white;
  padding: 0 0 0 24px;

  ${Text} {
    color: white;
  }
`;
const TopbarLink = styled(Link)`
  ${TypographyCss.Text}
  height: 50px;
  line-height: 50px;
  padding: 0 24px;
  color: white;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
const TopbarButton = styled.div`
  ${TypographyCss.Text}
  height: 50px;
  line-height: 50px;
  padding: 0 24px;
  color: white;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;
