import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { IComment } from '@shared/comments';
import { del, patch } from '@web/common/api';
import { TypographyCss } from '@web/components/typography';
import { Button, Tooltip, message } from 'antd';
import linkifyHtml from 'linkify-html';
import * as React from 'react';
import styled from 'styled-components';

import { InputComment } from './InputComment';

export const ViewEditComment: React.FC<{
  onChange: () => void;
  comment: IComment;
}> = ({ comment, onChange }) => {
  const [editing, setEditing] = React.useState(false);
  const handleSaveNote = async (newText: string) => {
    try {
      await patch(`/comments/${comment.token}`, { text: newText });
      setEditing(false);
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleDeleteNote = async () => {
    try {
      await del(`/comments/${comment.token}`);
      setEditing(false);
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  if (editing) {
    return (
      <EditNoteContainer>
        <InputComment
          initialValue={comment.text}
          onSubmit={handleSaveNote}
          autoFocus
          onCancel={() => {
            setEditing(false);
          }}
        />
      </EditNoteContainer>
    );
  }

  return (
    <ViewNoteContainer>
      <FloatingButtons>
        <Tooltip title="Edit comment">
          <Button
            type="text"
            size="small"
            onClick={() => {
              setEditing(true);
            }}
          >
            <EditOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Delete comment">
          <Button type="text" size="small" onClick={handleDeleteNote}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </FloatingButtons>
      <NoteHtml
        dangerouslySetInnerHTML={{
          __html: linkifyHtml(comment.text, { target: '_blank' }),
        }}
      />
    </ViewNoteContainer>
  );
};
const FloatingButtons = styled.section`
  position: absolute;
  top: -14px;
  right: 6px;
  border: 1px solid #ddd;
  border-radius: var(--default-border-radius);
  background: white;
  display: none;
  padding: 2px;
`;
const ViewNoteContainer = styled.section`
  position: relative;
  border-radius: var(--default-border-radius);
  width: 100%;

  &:hover {
    background: #f5f5f5;

    ${FloatingButtons} {
      display: block;
    }
  }
`;
const EditNoteContainer = styled.section`
  width: 100%;
`;
const NoteHtml = styled.section`
  ${TypographyCss.Text}
  line-height: 20px;
  padding-top: 2px;

  p,
  ul,
  ol {
    margin-bottom: 0px;
    word-wrap: break-word;
    min-height: 20px;
  }

  a {
    color: var(--primary-color);
  }

  *:last-child {
    margin-bottom: 0;
  }
`;
