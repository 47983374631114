import { PlusOutlined } from '@ant-design/icons';
import { mapByToken } from '@shared/mapByToken';
import { ActionItemToken, IActionItem, IAgendaItem } from '@shared/meetings';
import { patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { Column, Row, Spacer } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { Button, Skeleton, message } from 'antd';
import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { ActionItemRow } from './ActionItemRow';
import { NewActionItem } from './NewActionItem';

export interface ISortableActionItem<IdType = string | number> {
  id: IdType;
  title: string;
  resolved: boolean;
}

const actionToSortableItem = (
  actionItem: IActionItem,
): ISortableActionItem<ActionItemToken> => {
  return {
    id: actionItem.token,
    title: actionItem.title,
    resolved: !!actionItem.resolvedDate,
  };
};

export const ActionItemList: React.FC<{
  title: string;
  agendaItem: IAgendaItem;
}> = ({ title, agendaItem }) => {
  const [actionItemMap, setActionItemMap] =
    React.useState<Map<ActionItemToken, IActionItem>>(null);
  const { data: initialActionItems } = useApi<IActionItem[]>(
    `/action_items?agendaItem=${agendaItem.token}`,
  );
  const [sortableItems, setSortableItems] =
    React.useState<Array<ISortableActionItem<ActionItemToken>>>(null);
  const [showAdd, setShowAdd] = React.useState(false);
  React.useEffect(() => {
    if (initialActionItems) {
      const actionItemMap = mapByToken<IActionItem, ActionItemToken>(
        initialActionItems,
      );
      const initialSortableItems = agendaItem.sortedActionItems.map(
        (actionItemToken) =>
          actionToSortableItem(actionItemMap.get(actionItemToken)),
      );
      setActionItemMap(actionItemMap);
      setSortableItems(initialSortableItems);
    }
  }, [initialActionItems]);

  if (!sortableItems) {
    return (
      <Column gap={12}>
        <Header2>{title}</Header2>
        <Skeleton />
      </Column>
    );
  }

  const handleAddClicked = async () => {
    setShowAdd(true);
  };
  const handleAdded = async (title: string) => {
    try {
      const actionItem = await post<IActionItem>(`/action_items`, {
        title,
        agendaItemToken: agendaItem.token,
        meetingToken: agendaItem.meetingToken,
      });
      setSortableItems([...sortableItems, actionToSortableItem(actionItem)]);
      const newActionMap = new Map(actionItemMap);
      newActionMap.set(actionItem.token, actionItem);
      setActionItemMap(newActionMap);
      setShowAdd(false);
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleResolved = async (
    updatedItem: IActionItem,
    resolved: boolean,
  ) => {
    try {
      await patch<IActionItem>(`/action_items/${updatedItem.token}`, {
        title: updatedItem.title,
        resolvedDate: resolved ? new Date() : null,
      });
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleSortActionItemItems = async (
    newlySortedItems: Array<ISortableActionItem<ActionItemToken>>,
  ) => {
    if (hashIds(newlySortedItems) === hashIds(sortableItems)) {
      return;
    }

    setSortableItems(newlySortedItems);
    try {
      await put(
        `/action_items/sorted_action_items?agendaItem=${agendaItem.token}`,
        newlySortedItems.map((item) => item.id),
      );
    } catch (error) {
      void message.error('Error');
    }
  };

  const hasActionItemItems = sortableItems.length > 0;
  return (
    <Column>
      <Row gap={12}>
        <Header2>{title}</Header2>
        <Button type="text" size="small" onClick={handleAddClicked}>
          <PlusOutlined style={{ position: 'relative', top: -1 }} />
        </Button>
      </Row>
      <Spacer size={6} />
      <Column>
        {!hasActionItemItems && !showAdd && <Text>No items</Text>}
        {hasActionItemItems && (
          <ReactSortable
            list={sortableItems}
            setList={handleSortActionItemItems}
            easing="cubic-bezier(0.55, 0, 1, 0.45)"
            animation={100}
            handle=".drag-anchor"
          >
            {sortableItems.map((taskItem) => (
              <ActionItemRow
                key={taskItem.id}
                actionItem={actionItemMap.get(taskItem.id)}
                onResolve={handleResolved}
              />
            ))}
          </ReactSortable>
        )}
        {showAdd ? (
          <NewActionItem
            onAdd={handleAdded}
            onHide={() => {
              setShowAdd(false);
            }}
          />
        ) : (
          <Spacer size={8} />
        )}
      </Column>
    </Column>
  );
};

const hashIds = (taskItems: ISortableActionItem[]) =>
  taskItems.map((item) => item.id).join(':');
