import { EyeOutlined, FileSearchOutlined } from '@ant-design/icons';
import { IAgendaItem } from '@shared/meetings';
import { PageContent } from '@web/app/Page';
import { put } from '@web/common/api';
import { formatDate } from '@web/common/formatDate';
import { useApi } from '@web/common/useApi';
import { Row, Spacer } from '@web/components/layout';
import { Header1, Text } from '@web/components/typography';
import { Skeleton, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const AdminAgendaItemsPage: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const { data: agendaItems, mutate: reloadAgendaItems } = useApi(
    '/meetings/agenda_items/list',
  );

  const handleIndexAgendaItem = async (agendaItem: IAgendaItem) => {
    try {
      await put(`/search/index/agenda-items/${agendaItem.token}`);
      void message.success('Success');
      void reloadAgendaItems();
    } catch (error) {
      void message.error('Error');
    }
  };

  const columns: ColumnsType<IAgendaItem> = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: 'Created',
      key: 'createdDate',
      dataIndex: 'createdDate',
      render: (createdDate) =>
        createdDate ? <Text>{formatDate(createdDate)}</Text> : '',
    },
    {
      title: 'Indexed',
      key: 'indexed',
      dataIndex: 'indexedDate',
      render: (indexedDate) =>
        indexedDate ? <Text>{formatDate(indexedDate)}</Text> : '',
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      render: (_, agendaItem) => {
        return (
          <Row gap={8} style={{ justifyContent: 'flex-end' }}>
            <Tooltip title="Index agenda item">
              <FileSearchOutlined
                style={{ fontSize: 16, color: '#666' }}
                onClick={() => {
                  void handleIndexAgendaItem(agendaItem);
                }}
              />
            </Tooltip>
            <Tooltip title="View agenda item">
              <Link
                to={`/meetings/${agendaItem.meetingToken}/agenda-items/${agendaItem.token}`}
              >
                <EyeOutlined style={{ fontSize: 16, color: '#666' }} />
              </Link>
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <PageContent>
      <Header1>Agenda Items</Header1>
      <Spacer />
      {agendaItems ? (
        <Table
          rowKey="token"
          pagination={{
            total: agendaItems.length,
            onChange: setPage,
            current: page,
            pageSize: 50,
            showSizeChanger: false,
            showTotal: (total) => `${total} items`,
          }}
          columns={columns}
          dataSource={agendaItems}
        />
      ) : (
        <Skeleton />
      )}
    </PageContent>
  );
};
