import { IMeeting, MeetingState } from '@shared/meetings';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Small, TypographyCss } from '@web/components/typography';
import pluralize from 'pluralize';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { meetingTitle } from './meetingTitle';

export const MeetingListItem: React.FC<{ meeting: IMeeting }> = ({
  meeting,
}) => {
  return (
    <MeetingListItemContainer>
      <Row gap={12}>
        <MeetingHeaderLink
          to={`/meetings/${meeting.token}`}
          style={
            meeting.state === MeetingState.CLOSED
              ? { textDecoration: 'line-through' }
              : undefined
          }
        >
          {meetingTitle(meeting)}
        </MeetingHeaderLink>
        <GrowingSpacer />
        <Small>
          {meeting.agendaItems.length} agenda{' '}
          {pluralize('item', meeting.agendaItems.length)}
        </Small>
      </Row>
    </MeetingListItemContainer>
  );
};

const MeetingHeaderLink = styled(Link)`
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
  }
  ${TypographyCss.Text}
`;

const MeetingListItemContainer = styled(Column)`
  gap: 24px;
  max-height: 200px;
  overflow: hidden;
  position: relative;
`;
