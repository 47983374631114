import randomstring from 'randomstring';

export const generateToken =
  <T>(prefix: string, length = 8) =>
  (): T => {
    return `${prefix}${randomstring.generate(length)}` as T;
  };

export const isToken =
  <T extends string>(prefix: string) =>
  (testToken: string): testToken is T => {
    return !!testToken && testToken.startsWith(prefix);
  };
