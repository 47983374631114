import { OrganizationToken } from '@shared/organizations';
import { useAuth } from '@web/auth/useAuth';
import React, { createContext, useContext } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

interface OrganizationFilterValue {
  organizationTokenFilter?: OrganizationToken;
  setOrganizationTokenFilter: (token: OrganizationToken) => void;
}
const OrganizationFilterContext = createContext<OrganizationFilterValue>(null);

export const OrganizationFilterProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isInternal } = useAuth();
  const navigate = useNavigate();
  const match = useMatch('/organizations/:organizationToken/*');
  const location = useLocation();

  const organizationTokenFilter: OrganizationToken = match?.params
    ?.organizationToken as any;

  const setOrganizationTokenFilter = (organizationToken: OrganizationToken) => {
    if (
      organizationToken &&
      organizationTokenFilter !== organizationToken &&
      isInternal
    ) {
      const newPath = location.pathname.replace(
        organizationTokenFilter,
        organizationToken,
      );
      navigate(newPath);
    }
  };

  return (
    <OrganizationFilterContext.Provider
      value={{
        organizationTokenFilter,
        setOrganizationTokenFilter,
      }}
    >
      {children}
    </OrganizationFilterContext.Provider>
  );
};

export const useOrganizationFilter = (): OrganizationFilterValue =>
  useContext(OrganizationFilterContext) ?? {
    setOrganizationTokenFilter: () => {},
  };
