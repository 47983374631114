import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { IUser } from '@shared/types';
import { patch } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Checkbox } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

export const UserNotificationSettings: React.FC = () => {
  const { data: user } = useApi<IUser>(`/users/me`);

  if (!user) {
    return null;
  }

  return (
    <Column style={{ gap: 12 }}>
      <UserNotificationSetting
        user={user}
        label="Test"
        property="createdDate"
      />
    </Column>
  );
};

const UserNotificationSetting: React.FC<{
  user: IUser;
  property: keyof IUser;
  label: string;
}> = ({ user, property, label }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [updated, setUpdated] = React.useState(false);
  const [value, setValue] = React.useState(!!user[property]);

  const toggleSetting = async () => {
    setUpdated(false);
    setLoading(true);
    setError(false);
    const oldValue = value;
    const newValue = !oldValue;
    setValue(newValue);
    try {
      await patch(`/users/${user.token}`, { [property]: newValue });
      setUpdated(true);
    } catch (error) {
      setError(true);
      setValue(oldValue);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row
      style={{ gap: 10, cursor: 'pointer', alignItems: 'flex-start' }}
      onClick={() => {
        void toggleSetting();
      }}
    >
      <Checkbox checked={value} />
      <NotificationText>
        {label}
        {loading && <LoadingOutlined />}
        {updated && <CheckOutlined style={{ color: 'var(--color-success)' }} />}
        {error && <CloseOutlined style={{ color: 'var(--color-error)' }} />}
      </NotificationText>
    </Row>
  );
};

const NotificationText = styled(Text)`
  svg {
    margin-left: 10px;
  }
`;
