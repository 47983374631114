import { createGlobalStyle } from 'styled-components';

import { IF_MOBILE } from '../responsive';
import { AntStyles } from './AntStyles';
import { ColorStyles } from './ColorStyles';
import { MdxEditorStyles } from './MdxEditorStyles';
import { ModalStyles } from './ModalStyles';

export const GlobalStyles = createGlobalStyle`
  ${ColorStyles}

  body {
    background-color: var(--page-background);
    font-family: 'Poppins', sans-serif;      
    #root {
      width: 100%;
    }

    ${IF_MOBILE} {
      background-color: white;
      overflow-x: hidden;

      #root {
        width: 100vw;
      }
    }
  }

  a {
    text-decoration: none;
  }

  aside.EmojiPickerReact.epr-main {
    .epr-preview {
      display: none;
    }  
  }

  ${AntStyles}
  ${ModalStyles}
  ${MdxEditorStyles}
`;
