import { IF_MOBILE, useResponsive } from '@web/app/responsive';
import { Center, Column, Spacer } from '@web/components/layout';
import { Button, Input, Typography, message } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from './useAuth';

export const AdminLoginPage: React.FC = () => {
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { login } = useAuth();
  const { isMobile } = useResponsive();
  const [invalidEmail, setInvalidEmail] = React.useState<string>(null);
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('invalid_email')) {
      setInvalidEmail(queryParams.get('invalid_email'));
      navigate('/auth/login', { replace: true });
    }
  }, []);

  const handleSignInClicked = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await login(email, password);
      void message.success('Successfully logged in');
      // do a full page reload here to ensure user/session info is properly loaded
      window.location.replace('/redirect');
    } catch (error) {
      void message.error('Login failed');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginPageContainer>
      <Spacer size={!isMobile ? 100 : 0} />
      <Center>
        <LoginPagePane>
          <LoginFormContainer>
            <Spacer size={20} />
            {invalidEmail && (
              <>
                <Typography.Text type="danger" style={{ textAlign: 'center' }}>
                  The email address {'"'}
                  {invalidEmail}
                  {'"'} does not belong to a Condoo user. Please try another
                  email.
                </Typography.Text>
                <Spacer size={20} />
              </>
            )}
            <Input
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              placeholder="Email address"
              disabled={isLoading}
              autoFocus
            />
            <Spacer size={10} />
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              placeholder="Password"
              disabled={isLoading}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  void handleSignInClicked();
                }
              }}
            />
            <Spacer size={10} />
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={!email || !password}
              size="large"
              style={{ width: '100%' }}
              onClick={() => {
                void handleSignInClicked();
              }}
            >
              Continue
            </Button>
            <Spacer size={10} />
          </LoginFormContainer>
        </LoginPagePane>
      </Center>
    </LoginPageContainer>
  );
};

const LoginPageContainer = styled.div`
  background: #444;
  height: 100vh;
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;

  ${IF_MOBILE} {
    background: white;
  }
`;

const LoginPagePane = styled(Column)`
  background: white;
  width: 400px;
  padding: 30px;
  box-shadow: 0px 10px 30px rgb(0 0 0);

  ${IF_MOBILE} {
    padding: 24px;
    border-radius: var(--default-border-radius);
    box-shadow: none;
    border: 0;
  }
`;

const LoginFormContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
