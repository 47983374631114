import { OrganizationToken } from '@shared/organizations';
import { IUser, UserMapItem, UserToken } from '@shared/types';
import { Select } from 'antd';
import * as React from 'react';

import { UserAvatar } from '../UserAvatar';
import { Row } from '../layout';
import { Text } from '../typography';
import { useSelectUserSearch } from './useSelectUserSearch';

interface Props {
  disabled?: boolean;
  initialUser?: UserMapItem;
  onChange?: (newValue: UserToken, user: IUser) => void;
  organizationToken?: OrganizationToken;
  options?: IUser[];
  omitUserTokens?: UserToken[];
  style?: React.CSSProperties;
  placeholder?: string;
}

export const SelectUser: React.FC<Props> = ({
  disabled,
  initialUser,
  onChange,
  organizationToken,
  omitUserTokens = [],
  style,
  placeholder,
}) => {
  const { users, search, clearSearch } = useSelectUserSearch({
    organizationToken,
    omitUserTokens,
    allowEmptySearch: true,
  });
  const selectRef = React.useRef<{ blur: () => void }>();

  const userOptions = users.map((user) => ({
    value: user.token,
    label: (
      <Row gap={6}>
        <UserAvatar user={user} size={20} /> <Text>{user.name}</Text>
      </Row>
    ),
  }));

  const handleSelectChange = (option: { label: string; value: UserToken }) => {
    clearSearch();
    const userToken = option?.value ?? null;
    const user = userToken ? users.find((u) => u.token === userToken) : null;
    onChange(userToken, user);
    selectRef.current?.blur();
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      clearSearch();
    }
  };

  return (
    <Select
      placeholder={placeholder}
      disabled={disabled}
      notFoundContent={null}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      defaultValue={
        initialUser
          ? {
              label: (
                <Row gap={6}>
                  <UserAvatar user={initialUser} size={24} />{' '}
                  <Text>{initialUser.name}</Text>
                </Row>
              ),
              value: initialUser.token,
            }
          : undefined
      }
      onSearch={search}
      style={style}
      options={userOptions}
      filterOption={false}
      onChange={handleSelectChange as any}
      labelInValue={true}
      choiceTransitionName=""
      transitionName=""
      animation=""
      defaultActiveFirstOption={false}
      ref={
        selectRef as any
      } /** Not sure how to impact BaseSelectRef from rc-select */
    />
  );
};
