import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app/App';
import { IS_PRODUCTION } from './common/const';
import { initializeSentry } from './initializeSentry';

if (IS_PRODUCTION) {
  initializeSentry();
}
const root = createRoot(document.getElementById('root'));
root.render(<App />);
