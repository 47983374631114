import { SearchOutlined, TeamOutlined } from '@ant-design/icons';
import { IFile, isFile } from '@shared/files';
import { IAgendaItem } from '@shared/meetings';
import { useApi } from '@web/common/useApi';
import { Column, Row } from '@web/components/layout';
import { Small, Text, TypographyCss } from '@web/components/typography';
import { Dropdown } from 'antd';
import Input from 'antd/es/input/Input';
import { MenuProps } from 'antd/lib';
import { last } from 'lodash';
import * as React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SearchDropdown: React.FC<{
  onSelect?: (item: IFile) => void;
  placeholder?: string;
  style?: React.CSSProperties;
}> = ({ onSelect, placeholder, style }) => {
  const [query, setQuery] = React.useState('');
  const [showDropdown, setShowDropdown] = React.useState(false);
  const { data: searchResults } = useApi<{ items: Array<IFile | IAgendaItem> }>(
    query.length > 3 ? `/search?q=${query}` : null,
  );
  React.useEffect(() => {
    openDropdown();
  }, [searchResults]);

  const openDropdown = () => {
    if (searchResults?.items?.length > 0) {
      setShowDropdown(true);
    }
  };
  const closeDropdown = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };
  const handleQueryChanged = (newQuery) => {
    setQuery(newQuery);
    if (newQuery.length < 3) {
      setShowDropdown(false);
    }
  };
  const handleFileSelected = (file: IFile) => {
    setQuery('');
    closeDropdown();
    onSelect(file);
  };
  const reset = () => {
    setQuery('');
  };

  const items: MenuProps['items'] =
    searchResults?.items?.map((item) => ({
      key: item.token,
      label: isFile(item) ? (
        <FileDropdownItem
          file={item}
          onSelect={onSelect ? handleFileSelected : undefined}
          onNavigate={reset}
        />
      ) : (
        <AgendaItemDropdownItem agendaItem={item} />
      ),
    })) ?? [];

  return (
    <Dropdown
      open={showDropdown}
      menu={{ items }}
      arrow
      placement="top"
      onOpenChange={(open) => {
        if (!open) {
          setShowDropdown(open);
        }
      }}
      trigger={[]}
    >
      <Input
        value={query}
        onChange={(e) => {
          handleQueryChanged(e.target.value);
        }}
        placeholder={placeholder ?? 'Search for files'}
        style={style ?? { maxWidth: 240 }}
        prefix={<SearchOutlined />}
        onClick={openDropdown}
        onFocus={openDropdown}
        onBlur={closeDropdown}
      />
    </Dropdown>
  );
};

const FileDropdownItem: React.FC<{
  file: IFile;
  onSelect: (file: IFile) => void;
  onNavigate?: () => void;
}> = ({ file, onSelect, onNavigate }) => {
  return onSelect ? (
    <FileLabelButton
      file={file}
      onClick={() => {
        onSelect(file);
      }}
    />
  ) : (
    <FileLabelLink
      file={file}
      onClick={() => {
        onNavigate?.();
      }}
    />
  );
};

const FileLabelLink: React.FC<{ file: IFile; onClick: () => void }> = ({
  file,
  onClick,
}) => {
  const extension = last(file.name.split('.'));

  return (
    <FileLabelLinkContainer
      href={`/api/files/${file.token}/${file.name}`}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
    >
      <Row gap={12} style={{ alignItems: 'flex-start', padding: '6px 0' }}>
        <Column style={{ width: 24, height: 24, marginTop: 6 }}>
          <FileIcon extension={extension} {...defaultStyles[extension]} />
        </Column>
        <Column>
          <Text>{file.name}</Text>
          <Small
            style={{
              color: '#888',
              fontStyle: file.description ? undefined : 'italic',
            }}
          >
            {file.description ?? 'No description'}
          </Small>
        </Column>
      </Row>
    </FileLabelLinkContainer>
  );
};
const AgendaItemDropdownItem: React.FC<{
  agendaItem: IAgendaItem;
  onNavigate?: () => void;
}> = ({ agendaItem, onNavigate }) => {
  return (
    <AgendaItemLink
      to={`/meetings/${agendaItem.meetingToken}/agenda-items/${agendaItem.token}`}
      onClick={() => {
        onNavigate?.();
      }}
    >
      <Row gap={12} style={{ alignItems: 'flex-start', padding: '6px 0' }}>
        <Column style={{ width: 24, height: 24, marginTop: 6 }}>
          <TeamOutlined />
        </Column>
        <Column>
          <Text>{agendaItem.title}</Text>
          <Small
            style={{
              color: '#888',
              fontStyle: agendaItem.description ? undefined : 'italic',
            }}
          >
            {agendaItem.description ?? 'No description'}
          </Small>
        </Column>
      </Row>
    </AgendaItemLink>
  );
};
const AgendaItemLink = styled(Link)`
  ${TypographyCss.Small}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 28px;

  svg {
    width: 14px;
    height: 14px;
  }
`;
const FileLabelLinkContainer = styled.a`
  ${TypographyCss.Small}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 28px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const FileLabelButton: React.FC<{ file: IFile; onClick: () => void }> = ({
  file,
  onClick,
}) => {
  const extension = last(file.name.split('.'));

  return (
    <FileLabelButtonContainer onClick={onClick}>
      <Row gap={12} style={{ alignItems: 'flex-start', padding: '6px 0' }}>
        <Column style={{ width: 24, height: 24, marginTop: 6 }}>
          <FileIcon extension={extension} {...defaultStyles[extension]} />
        </Column>
        <Column>
          <Text>{file.name}</Text>
          <Small
            style={{
              color: '#888',
              fontStyle: file.description ? undefined : 'italic',
            }}
          >
            {file.description ?? 'No description'}
          </Small>
        </Column>
      </Row>
    </FileLabelButtonContainer>
  );
};
const FileLabelButtonContainer = styled.div`
  ${TypographyCss.Small}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 28px;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
`;
