import { ITopic, TopicToken } from '@shared/topics';
import { UserMapItem } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SimpleSelectUser } from '@web/components/users/SimpleSelectUser';
import { Button, Input, Skeleton, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  onSave: (topic: ITopic) => void;
  onCancel: () => void;
}
export const EditTopic: React.FC<Props> = ({ onSave, onCancel }) => {
  const { user } = useAuth();
  const { topicToken } = useParams<{ topicToken: TopicToken }>();
  const { data } = useApi<{ topic: ITopic }>(
    topicToken ? `/topics/${topicToken}` : null,
  );
  const isEdit = !!topicToken;
  const [isSaving, setIsSaving] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');
  const [owner, setOwner] = React.useState<UserMapItem>(user);
  React.useEffect(() => {
    if (data?.topic) {
      setTitle(data?.topic.title);
      setContent(data?.topic.content);
      setOwner(data?.topic.owner);
    }
  }, [data?.topic]);

  if (isEdit && !data?.topic) {
    return <Skeleton />;
  }

  const handleSave = async () => {
    if (!title || isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      let topic;
      if (isEdit) {
        topic = await patch<ITopic>(`/topics/${topicToken}`, {
          title,
          content,
          ownerToken: owner.token,
        });
      } else {
        topic = await post<ITopic>(`/topics`, {
          title,
          content,
          ownerToken: owner.token,
        });
      }
      void message.success('Success');
      onSave(topic);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const canSave = !!title;

  return (
    <Column gap={24}>
      <FormElement>
        <Text>Title</Text>
        <Input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          autoFocus={!isEdit}
        />
      </FormElement>
      <FormElement>
        <SimpleSelectUser
          value={owner}
          onChange={(owner) => {
            setOwner(owner);
          }}
        />
      </FormElement>

      <FormElement>
        <Text>Content</Text>
        <TextArea
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          style={{ height: 200 }}
        />
      </FormElement>
      <Row gap={6}>
        <Button type="primary" onClick={handleSave} disabled={!canSave}>
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Row>
    </Column>
  );
};

const FormElement = styled(Column)`
  gap: 12px;
  max-width: 600px;
`;
