import { UserToken } from './types';
import { OrganizationToken } from './organizations';

export enum NotificationType {
  TEST = 'TEST',
}

export enum NotificationStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  DEBUG_SENT = 'DEBUG_SENT',
  ERROR = 'ERROR',
}

export interface INotification {
  token: string;
  type: NotificationType;
  status: NotificationStatus;
  userToken?: UserToken;
  organizationToken: OrganizationToken;
  error?: string;
  content: string;
  resentFrom?: string;
  resentBy?: string;
  createdDate: Date;
  updatedDate: Date;
}
