"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prepareComponentToken = exports.initPickerPanelToken = exports.initPanelComponentToken = void 0;
var _tinycolor = require("@ctrl/tinycolor");
var _token = require("../../input/style/token");
var _roundedArrow = require("../../style/roundedArrow");
const initPickerPanelToken = token => {
  const {
    componentCls,
    controlHeightLG,
    paddingXXS,
    padding
  } = token;
  return {
    pickerCellCls: `${componentCls}-cell`,
    pickerCellInnerCls: `${componentCls}-cell-inner`,
    pickerYearMonthCellWidth: token.calc(controlHeightLG).mul(1.5).equal(),
    pickerQuarterPanelContentHeight: token.calc(controlHeightLG).mul(1.4).equal(),
    pickerCellPaddingVertical: token.calc(paddingXXS).add(token.calc(paddingXXS).div(2)).equal(),
    pickerCellBorderGap: 2,
    // Magic for gap between cells
    pickerControlIconSize: 7,
    pickerControlIconMargin: 4,
    pickerControlIconBorderWidth: 1.5,
    pickerDatePanelPaddingHorizontal: token.calc(padding).add(token.calc(paddingXXS).div(2)).equal() // 18 in normal
  };
};
exports.initPickerPanelToken = initPickerPanelToken;
const initPanelComponentToken = token => {
  const {
    colorBgContainerDisabled,
    controlHeight,
    controlHeightSM,
    controlHeightLG,
    paddingXXS
  } = token;
  return {
    cellHoverBg: token.controlItemBgHover,
    cellActiveWithRangeBg: token.controlItemBgActive,
    cellHoverWithRangeBg: new _tinycolor.TinyColor(token.colorPrimary).lighten(35).toHexString(),
    cellRangeBorderColor: new _tinycolor.TinyColor(token.colorPrimary).lighten(20).toHexString(),
    cellBgDisabled: colorBgContainerDisabled,
    timeColumnWidth: controlHeightLG * 1.4,
    timeColumnHeight: 28 * 8,
    timeCellHeight: 28,
    cellWidth: controlHeightSM * 1.5,
    cellHeight: controlHeightSM,
    textHeight: controlHeightLG,
    withoutTimeCellHeight: controlHeightLG * 1.65,
    multipleItemBg: token.colorFillSecondary,
    multipleItemBorderColor: 'transparent',
    multipleItemHeight: controlHeight - paddingXXS * 2,
    multipleItemHeightSM: controlHeightSM - paddingXXS * 2,
    multipleItemHeightLG: controlHeightLG - paddingXXS * 2,
    multipleSelectorBgDisabled: colorBgContainerDisabled,
    multipleItemColorDisabled: token.colorTextDisabled,
    multipleItemBorderColorDisabled: 'transparent'
  };
};
exports.initPanelComponentToken = initPanelComponentToken;
const prepareComponentToken = token => Object.assign(Object.assign(Object.assign(Object.assign({}, (0, _token.initComponentToken)(token)), initPanelComponentToken(token)), (0, _roundedArrow.getArrowToken)(token)), {
  presetsWidth: 120,
  presetsMaxWidth: 200,
  zIndexPopup: token.zIndexPopupBase + 50
});
exports.prepareComponentToken = prepareComponentToken;