import { ITopic } from '@shared/topics';
import { Column, Row, Spacer } from '@web/components/layout';
import { TypographyCss } from '@web/components/typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FileButton } from '../admin/FileButton';
import { TopicInfo } from './TopicInfo';

export const TopicListItem: React.FC<{ topic: ITopic }> = ({ topic }) => {
  return (
    <TopicListItemContainer>
      <Column>
        <TopicHeaderLink to={`/topics/${topic.token}`}>
          {topic.title}
        </TopicHeaderLink>
        <Spacer size={6} />
        <TopicInfo topic={topic} />
        <Spacer size={6} />
        {topic.files?.length > 0 && (
          <Row style={{ flexWrap: 'wrap' }} gap={6}>
            {topic.files.map((file) => (
              <FileButton file={file} key={file.token} />
            ))}
          </Row>
        )}
      </Column>
    </TopicListItemContainer>
  );
};

const TopicHeaderLink = styled(Link)`
  flex: 1;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
  }

  ${TypographyCss.Header2}
`;

const TopicListItemContainer = styled(Column)`
  gap: 24px;
  max-height: 200px;
  overflow: hidden;
  position: relative;
`;
