import { Modal, ModalFuncProps, message } from 'antd';
import * as React from 'react';

export const useModalConfirm = () => {
  const [modal, contextHolder] = Modal.useModal();
  const confirm = (content: React.ReactNode, props: ModalFuncProps = {}) =>
    modal.confirm({
      title: props.title ?? 'Are you sure?',
      content,
      icon: null,
      okText: props.okText ?? 'Continue',
      cancelText: props.cancelText,
    });
  const confirmThenDo =
    (confirmMessage: string, thingToDo: () => Promise<any>) => async () => {
      const userConfirmed = await confirm(confirmMessage);
      if (!userConfirmed) {
        return;
      }

      try {
        await thingToDo();
        void message.success('Success');
      } catch (error) {
        void message.error('Error');
      }
    };

  return { contextHolder, confirm, confirmThenDo };
};
