import styled, { css } from 'styled-components';

export const TypographyColors = {
  text: '#515c65',
  header: '#2D3941',
  headerDark: '#12171A',
  subdued: '#ADB5BB',
};

export const commonTypographyStyles = css`
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
`;

export const TypographyCss = {
  Small: css`
    ${commonTypographyStyles}
    color: ${TypographyColors.text};
    font-size: 12px;
    line-height: 18px;
  `,
  Text: css`
    ${commonTypographyStyles}
    color: ${TypographyColors.text};
    font-size: 14px;
    line-height: 24px;
  `,
  Header3: css`
    ${commonTypographyStyles}
    color: ${TypographyColors.header};
    font-size: 16px;
    line-height: 24px;
  `,
  Header2: css`
    ${commonTypographyStyles}
    color: ${TypographyColors.headerDark};
    font-size: 18px;
    line-height: 24px;
  `,
  Header1: css`
    ${commonTypographyStyles}
    color: ${TypographyColors.headerDark};
    font-size: 24px;
    line-height: 36px;
  `,
};

export const SubduedText = styled.p`
  ${TypographyCss.Text};
  color: ${TypographyColors.subdued};
  margin: 0;
`;

export const Small = styled.small`
  ${TypographyCss.Small};
  margin: 0;
`;

export const Text = styled.p<{ strong?: boolean }>`
  ${TypographyCss.Text};
  margin: 0;
  ${(props) => (props.strong ? 'font-weight: 600;' : '')}
`;

export const Italic = styled(Text)`
  font-style: italic;
`;

export const TruncateText = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Header3 = styled.h3`
  ${TypographyCss.Header3};
  margin: 0;
`;

export const Header2 = styled.h2`
  ${TypographyCss.Header2};
  margin: 0;
`;

export const Header1 = styled.h1`
  ${TypographyCss.Header1};
  margin: 0;
`;
