import {
  IAutoScheduledEvent,
  IManualScheduledEvent,
  IScheduledEvent,
  ScheduledEventUpdate,
} from '@shared/scheduled_event';
import { DefaultTimezone, SupportedTimezone } from '@shared/timezones';
import { addDays, format, nextTuesday } from 'date-fns';
import { get } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';

export interface ScheduledEventState {
  manual: boolean;
  setManual: Dispatch<SetStateAction<boolean>>;

  date: string;
  setDate: Dispatch<SetStateAction<string>>;

  hour: number;
  setHour: Dispatch<SetStateAction<number>>;

  onChange: (newDate: string, newHour: number) => void;

  timezone?: SupportedTimezone;
  setTimezone: Dispatch<SetStateAction<SupportedTimezone>>;

  setAll: (event: IScheduledEvent) => void;
  getEntity: () => ScheduledEventUpdate;
}

export const useScheduledEvent = (
  initialEvent?: Partial<IScheduledEvent>,
): ScheduledEventState => {
  const [manual, setManual] = useState(initialEvent?.manual ?? false);
  const [date, setDate] = useState(
    initialEvent?.date ??
      format(nextTuesday(addDays(new Date(), 6)), 'yyyy-MM-dd'),
  );
  const [hour, setHour] = useState<number>(get(initialEvent, 'hour') ?? 9);
  const initialTimezone: SupportedTimezone =
    (get(initialEvent, 'timezone') as SupportedTimezone) ?? DefaultTimezone;
  const [timezone, setTimezone] = useState<SupportedTimezone | undefined>(
    initialTimezone,
  );
  const getEntity = () => {
    if (manual) {
      const scheduledEvent: IManualScheduledEvent = {
        manual: true,
        date,
      } as any;
      return scheduledEvent;
    }

    if (!timezone) {
      throw new Error('no timezone set');
    }
    const scheduledEvent: IAutoScheduledEvent = {
      manual: false,
      date,
      hour,
      timezone,
    } as any;
    return scheduledEvent;
  };
  const setAll = (event: IScheduledEvent) => {
    switch (event.manual) {
      case true: {
        setManual(true);
        setDate(event.date);
        setHour(9);
        setTimezone(DefaultTimezone);
        break;
      }
      case false: {
        setManual(false);
        setDate(event.date);
        setHour(event.hour);
        setTimezone(event.timezone);
        break;
      }
    }
  };

  const onChange = (newDate: string, newHour: number) => {
    setDate(newDate);
    setHour(newHour);
  };

  return {
    manual,
    setManual,
    date,
    setDate,
    hour,
    setHour,
    timezone,
    setTimezone,
    getEntity,
    onChange,
    setAll,
  };
};
