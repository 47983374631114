/* eslint-disable @typescript-eslint/promise-function-async */
import * as React from 'react';

import * as NetworkErrorPageModule from './NetworkErrorPage';

export const EditUserPage = React.lazy(() =>
  import('@web/users/UserPage/EditUserPage').catch(
    () => NetworkErrorPageModule,
  ),
);
export const UsersPage = React.lazy(() =>
  import('@web/users/UsersPage').catch(() => NetworkErrorPageModule),
);
export const OrganizationPage = React.lazy(() =>
  import('@web/organizations/OrganizationPage/OrganizationPage').catch(
    () => NetworkErrorPageModule,
  ),
);
