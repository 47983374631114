import {
  CloseCircleOutlined,
  SearchOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { IFile, isFile } from '@shared/files';
import { IAgendaItem } from '@shared/meetings';
import { PageContent } from '@web/app/Page';
import { get } from '@web/common/api';
import { Column, Row, Spacer } from '@web/components/layout';
import { Small, Text, TypographyCss } from '@web/components/typography';
import { Input } from 'antd';
import { last } from 'lodash';
import pluralize from 'pluralize';
import * as React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HomePage: React.FC = () => {
  const [query, setQuery] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<
    Array<IFile | IAgendaItem>
  >([]);

  const search = async (query: string) => {
    if (query.length === 0) {
      setSearchResults([]);
      return;
    }

    const results = await get<{ items: Array<IFile | IAgendaItem> }>(
      `/search?q=${query}&limit=50`,
    );
    setSearchResults(results.items);
  };
  const handleClear = async () => {
    setQuery('');
    await search('');
  };

  return (
    <PageContent>
      <Column style={{ width: 500, alignSelf: 'center' }}>
        <SearchInput
          suffix={
            query.length > 0 ? (
              <ClearButton onClick={handleClear}>
                <CloseCircleOutlined />
              </ClearButton>
            ) : null
          }
          prefix={<SearchOutlined />}
          placeholder="What are you looking for?"
          value={query}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              void search(query);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onChange={(e) => {
            setQuery(e.currentTarget.value);
          }}
        />
        <Spacer />
        {searchResults.length > 0 && (
          <Column gap={18}>
            <Text>
              Found {searchResults.length}{' '}
              {pluralize('result', searchResults.length)}:
            </Text>
            {searchResults?.map((result) =>
              isFile(result) ? (
                <FileResult key={result.token} file={result} />
              ) : (
                <AgendaItemResult key={result.token} agendaItem={result} />
              ),
            )}
          </Column>
        )}
      </Column>
    </PageContent>
  );
};

const SearchInput = styled(Input)`
  height: 50px;
  font-size: 16px;

  .ant-input-prefix {
    margin-inline-end: 0px;
    margin: 0 12px 0 6px;
  }
`;

const ClearButton = styled.div`
  cursor: pointer;
  color: #aaa;

  &:hover {
    color: #333;
  }
`;

const FileResult: React.FC<{ file: IFile; onClick?: () => void }> = ({
  file,
  onClick,
}) => {
  const extension = last(file.name.split('.'));

  return (
    <FileLabelLinkContainer
      href={`/api/files/${file.token}/${file.name}`}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
    >
      <Row gap={12} style={{ alignItems: 'flex-start', padding: '6px 0' }}>
        <FileIcon extension={extension} {...defaultStyles[extension]} />
        <Column>
          <ResultText>{file.name}</ResultText>
          <Small
            style={{
              color: '#888',
              fontStyle: file.description ? undefined : 'italic',
            }}
          >
            {file.description ?? 'No description'}
          </Small>
        </Column>
      </Row>
    </FileLabelLinkContainer>
  );
};
const AgendaItemResult: React.FC<{
  agendaItem: IAgendaItem;
  onNavigate?: () => void;
}> = ({ agendaItem, onNavigate }) => {
  return (
    <AgendaItemLink
      to={`/meetings/${agendaItem.meetingToken}/agenda-items/${agendaItem.token}`}
      onClick={() => {
        onNavigate?.();
      }}
    >
      <Row gap={12} style={{ alignItems: 'flex-start', padding: '6px 0' }}>
        <TeamOutlined style={{ fontSize: 36 }} />
        <Column>
          <ResultText>{agendaItem.title}</ResultText>
          <Small
            style={{
              color: '#888',
              fontStyle: agendaItem.description ? undefined : 'italic',
            }}
          >
            {agendaItem.description ?? 'No description'}
          </Small>
        </Column>
      </Row>
    </AgendaItemLink>
  );
};
const ResultText = styled(Text)``;

const AgendaItemLink = styled(Link)`
  ${TypographyCss.Small}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 28px;

  svg {
    width: 36px;
    height: 36px;
  }

  &:hover {
    ${ResultText} {
      text-decoration: underline;
    }
  }
`;
const FileLabelLinkContainer = styled.a`
  ${TypographyCss.Small}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 28px;

  svg {
    width: 36px;
    height: 36px;
    margin-top: 3px;
  }

  &:hover {
    ${ResultText} {
      text-decoration: underline;
    }
  }
`;
