import { EditOutlined, LeftOutlined } from '@ant-design/icons';
import { PageActions } from '@web/app/PageActions';
import { IPageAction, PageContext } from '@web/app/PageContext';
import { useResponsive } from '@web/app/responsive';
import { Header1, Small, Text } from '@web/components/typography';
import React from 'react';
import styled from 'styled-components';

import { useBackNavigation } from '../common/useBackNavigation';
import { Column, GrowingSpacer, Row } from './layout';

export const PageHeader: React.FC<{
  title?: React.ReactNode;
  mobileTitle?: string;
  description?: React.ReactNode;
  navigateBack?: boolean;
  defaultNavigateBackTo?: string;
  primaryAction?: IPageAction;
  secondaryActions?: Array<IPageAction | null>;
  extras?: React.ReactNode;
  onEdit?: () => void;
}> = ({
  title,
  mobileTitle,
  description,
  navigateBack,
  defaultNavigateBackTo,
  primaryAction,
  secondaryActions,
  extras,
  onEdit,
}) => {
  const { setPageDetails } = React.useContext(PageContext);
  const { isMobile } = useResponsive();

  React.useLayoutEffect(() => {
    setPageDetails({
      title: mobileTitle,
      navigateBack,
      defaultNavigateBackTo,
      primaryAction,
      secondaryActions,
    });
  }, [mobileTitle, navigateBack]);

  const { goBack } = useBackNavigation(
    navigateBack ?? false,
    defaultNavigateBackTo,
  );

  const nonNullSecondaryActions: IPageAction[] | undefined =
    secondaryActions?.filter((action) => !!action);
  const showHeader =
    !!goBack || !!primaryAction || !!nonNullSecondaryActions || !!title;

  if (isMobile || !showHeader) {
    return null;
  }

  return (
    <Column style={{ alignItems: 'flex-start', marginBottom: 20 }}>
      {goBack && (
        <BackButton onClick={goBack}>
          <Small>
            <LeftOutlined /> BACK
          </Small>
        </BackButton>
      )}
      <Column style={{ width: '100%' }}>
        <Row>
          {title && (
            <Header1
              id="page-title"
              style={{
                margin: 0,
                lineHeight: '40px',
              }}
            >
              {title}
            </Header1>
          )}
          {onEdit && (
            <EditOutlined
              onClick={onEdit}
              style={{ fontSize: 18, marginLeft: 16 }}
            />
          )}
          <GrowingSpacer min={10} />
          {extras ?? (
            <PageActions
              primaryAction={primaryAction}
              secondaryActions={nonNullSecondaryActions}
            />
          )}
        </Row>
        {description && (
          <Text style={{ marginTop: 0, marginLeft: 2 }}>{description}</Text>
        )}
      </Column>
    </Column>
  );
};

const BackButton = styled.button`
  appearance: none;
  padding: 0;
  font-size: 14px;
  color: var(--color-text);
  border: 0;
  background: transparent;
  cursor: pointer;
  text-align: left;
  padding: 2px 6px 2px 0;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;
