import { PlusOutlined, QuestionOutlined } from '@ant-design/icons';
import { mapByToken } from '@shared/mapByToken';
import {
  AgendaItemToken,
  AgendaLabel,
  IAgendaItem,
  IMeeting,
} from '@shared/meetings';
import { put } from '@web/common/api';
import { Row, Spacer } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Tag } from 'antd';
import * as React from 'react';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';

import { AddAgendaItemModal } from './AddAgendaItemModal';

interface Props {
  meeting: IMeeting;
  onSelect: (agendaItem: IAgendaItem) => void;
  onAdd: (agendaItem: IAgendaItem) => void;
  selectedAgendaItem?: IAgendaItem;
  readonly?: boolean;
  height: string;
}

interface SortableAgendaItem {
  id: AgendaItemToken;
  chosen?: boolean;
}

const createSortedAgendaItems = (meeting: IMeeting): SortableAgendaItem[] => {
  return (
    meeting.agenda?.map((agendaItemToken) => ({
      id: agendaItemToken,
    })) ??
    meeting.agendaItems?.map((item) => ({ id: item.token })) ??
    []
  );
};

export const AgendaItemsList: React.FC<Props> = ({
  meeting,
  readonly,
  onSelect,
  onAdd,
  selectedAgendaItem,
  height,
}) => {
  const [showAddAgendaItem, setShowAddAgendaItem] = React.useState(false);
  const [sortedAgendaItems, setSortedAgendaItems] = React.useState<
    SortableAgendaItem[]
  >(createSortedAgendaItems(meeting));
  React.useEffect(() => {
    setSortedAgendaItems(createSortedAgendaItems(meeting));
  }, [meeting]);

  const handleAgendaItemAdded = (agendaItem: IAgendaItem) => {
    setShowAddAgendaItem(false);
    onAdd(agendaItem);
  };

  const handleAgendaItemCancel = () => {
    setShowAddAgendaItem(false);
  };

  const hashIds = (agendaItems: SortableAgendaItem[]) => {
    return agendaItems.map((item) => item.id).join(':');
  };

  const handleSortAgendaItems = async (agendaItems: SortableAgendaItem[]) => {
    if (hashIds(agendaItems) === hashIds(sortedAgendaItems)) {
      return;
    }

    setSortedAgendaItems(agendaItems);
    void put(
      `/meetings/${meeting.token}/agenda`,
      agendaItems.map((item) => item.id),
    );
  };

  const agendaItemMap = mapByToken(meeting.agendaItems);
  const filteredAgendaItems = sortedAgendaItems.filter((agendaItem) =>
    agendaItemMap.has(agendaItem.id),
  );
  return (
    <MeetingAgenda style={{ height }}>
      <ListItemContainer
        onClick={() => {
          onSelect(null);
        }}
        style={{
          background: !selectedAgendaItem ? '#eee' : 'white',
        }}
      >
        <ListItemIcon>
          <QuestionOutlined />
        </ListItemIcon>
        <Spacer size={12} />
        <Text>Board Meeting - {meeting.date.date}</Text>
      </ListItemContainer>
      <ReactSortable
        easing="cubic-bezier(0.55, 0, 1, 0.45)"
        animation={200}
        list={sortedAgendaItems}
        setList={handleSortAgendaItems}
      >
        {filteredAgendaItems.map((agendaItem, index) => (
          <AgendaListItem
            key={agendaItem.id}
            position={index}
            agendaItem={agendaItemMap.get(agendaItem.id)}
            onSelect={() => {
              onSelect(agendaItemMap.get(agendaItem.id));
            }}
            selected={agendaItem.id === selectedAgendaItem?.token}
            dragging={agendaItem.chosen}
          />
        ))}
      </ReactSortable>
      {!readonly && (
        <ListItemContainer
          onClick={() => {
            setShowAddAgendaItem(true);
          }}
        >
          <ListItemIcon>
            <PlusOutlined />
          </ListItemIcon>
          <Spacer size={12} />
          <Text>Add agenda item</Text>
        </ListItemContainer>
      )}
      {showAddAgendaItem && (
        <AddAgendaItemModal
          meeting={meeting}
          onAdd={handleAgendaItemAdded}
          onClose={handleAgendaItemCancel}
        />
      )}
    </MeetingAgenda>
  );
};

const AgendaListItem: React.FC<{
  agendaItem: IAgendaItem;
  position: number;
  onSelect: (agendaItem: IAgendaItem) => void;
  selected?: boolean;
  dragging?: boolean;
}> = ({
  position,
  agendaItem,
  onSelect,
  selected = false,
  dragging = false,
}) => {
  return (
    <ListItemContainer
      onClick={() => {
        onSelect(agendaItem);
      }}
      style={{
        background: dragging || selected ? '#eee' : 'white',
      }}
    >
      <ListItemIcon>
        <Header3>{position + 1}</Header3>
      </ListItemIcon>
      <Spacer size={12} />
      <Text
        style={
          agendaItem.resolvedDate ? { textDecoration: 'line-through' } : {}
        }
      >
        {agendaItem.title ?? 'No title'}
      </Text>
      <Spacer size={12} />
      {agendaItem.labels.length > 0 && (
        <Tag color={LabelTagColours[agendaItem.labels[0]]}>
          {agendaItem.labels[0]}
        </Tag>
      )}
    </ListItemContainer>
  );
};

const LabelTagColours = {
  [AgendaLabel.APPROVAL]: 'blue',
  [AgendaLabel.DISCUSS]: 'green',
  [AgendaLabel.INFO]: 'yellow',
};

const MeetingAgenda = styled.section`
  overflow-x: hidden;
  border-right: 1px solid #eee;
  height: calc(100vh - 50px);
  overflow-y: auto;
`;

const ListItemContainer = styled(Row)`
  position: relative;
  padding: 14px 18px;
  cursor: pointer;
  height: 80px;
  border-bottom: 1px solid #eee;

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
`;

const ListItemIcon = styled.div`
  padding: 3px;
  border: 1px solid #333;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
