import {
  CloseCircleOutlined,
  EditFilled,
  EyeOutlined,
  FileSearchOutlined,
  LoadingOutlined,
  RobotOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { IFile } from '@shared/files';
import { PageContent } from '@web/app/Page';
import { del, put } from '@web/common/api';
import { formatDate } from '@web/common/formatDate';
import { useApi } from '@web/common/useApi';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header1, Small, Text } from '@web/components/typography';
import { EditFileModal } from '@web/meetings/agenda_items/EditFileModal';
import { FileIcon } from '@web/topics/FileIcon';
import { Button, Skeleton, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { UploadChangeParam } from 'antd/es/upload';
import { Table, Upload, UploadFile } from 'antd/lib';
import { last } from 'lodash';
import * as React from 'react';

export const AdminFilesPage: React.FC = () => {
  const { confirm, contextHolder } = useModalConfirm();
  const [editingFile, setEditingFile] = React.useState<IFile>(null);
  const [page, setPage] = React.useState(1);
  const { data: files, mutate: reloadFiles } = useApi('/files/list');

  const handleRemoveFile = async (file: IFile) => {
    const confirmed = await confirm(
      'Do you wish to permanently remove this file?',
      { title: 'Delete File' },
    );
    if (!confirmed) {
      return;
    }

    try {
      await del(`/files/${file.token}`);
      void message.success('Success');
      void reloadFiles();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleIndexFile = async (file: IFile) => {
    try {
      await put(`/search/index/files/${file.token}`);
      void message.success('Success');
      void reloadFiles();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleExtractEstimate = async (file: IFile) => {
    try {
      await put(`/files/${file.token}/extract-estimate`);
      void message.success('Success');
      void reloadFiles();
    } catch (error) {
      void message.error('Error');
    }
  };

  const columns: ColumnsType<IFile> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      render: (_, file) => {
        const extension = last(file.name.split('.'));

        return (
          <Row gap={12}>
            <FileIcon extension={extension} size={28} />
            <Column>
              <Text>{file.name}</Text>
              <Small
                style={{
                  color: '#888',
                  fontStyle: file.description ? undefined : 'italic',
                }}
              >
                {file.description ?? 'No description'}
              </Small>
            </Column>
          </Row>
        );
      },
    },
    {
      title: 'Uploaded By',
      key: 'owner',
      dataIndex: 'owner',
      render: (owner) =>
        owner ? (
          <Row gap={6}>
            <UserAvatar user={owner} size={24} />
            <Text>{owner.name}</Text>
          </Row>
        ) : (
          ''
        ),
    },
    {
      title: 'Uploaded',
      key: 'createdDate',
      dataIndex: 'createdDate',
      render: (createdDate) =>
        createdDate ? <Text>{formatDate(createdDate)}</Text> : '',
    },

    {
      title: 'Indexed',
      key: 'indexed',
      dataIndex: 'indexedDate',
      render: (indexedDate) =>
        indexedDate ? <Text>{formatDate(indexedDate)}</Text> : '',
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      render: (_, file) => {
        return (
          <Row gap={8} style={{ justifyContent: 'flex-end' }}>
            <Tooltip title="Extract Estimate">
              <RobotOutlined
                style={{ fontSize: 16, color: '#666' }}
                onClick={() => {
                  void handleExtractEstimate(file);
                }}
              />
            </Tooltip>
            <Tooltip title="Index attachment">
              <FileSearchOutlined
                style={{ fontSize: 16, color: '#666' }}
                onClick={() => {
                  void handleIndexFile(file);
                }}
              />
            </Tooltip>
            <Tooltip title="View attachment">
              <a
                href={`/api/files/${file.token}/${file.name}`}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <EyeOutlined
                  style={{ fontSize: 16, color: '#666' }}
                  onClick={() => {
                    void handleIndexFile(file);
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip title="Edit attachment">
              <EditFilled
                style={{ fontSize: 16, color: '#666' }}
                onClick={() => {
                  setEditingFile(file);
                }}
              />
            </Tooltip>
            <Tooltip title="Remove attachment">
              <CloseCircleOutlined
                style={{ fontSize: 16, color: '#666' }}
                onClick={() => {
                  void handleRemoveFile(file);
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <PageContent>
      <Row>
        <Header1>Files</Header1>
        <GrowingSpacer />
        <UploadNewFile
          onUpload={() => {
            void reloadFiles();
          }}
        />
      </Row>
      <Spacer />
      {files ? (
        <Table
          rowKey="token"
          pagination={{
            total: files.length,
            onChange: setPage,
            current: page,
            pageSize: 50,
            showSizeChanger: false,
            showTotal: (total) => `${total} files`,
          }}
          columns={columns}
          dataSource={files}
        />
      ) : (
        <Skeleton />
      )}
      {contextHolder}
      {editingFile && (
        <EditFileModal
          file={editingFile}
          onClose={() => {
            setEditingFile(null);
          }}
          onSave={() => {
            setEditingFile(null);
            void reloadFiles();
          }}
        />
      )}
    </PageContent>
  );
};

const UploadNewFile: React.FC<{
  onUpload?: (newFile: IFile) => void;
}> = ({ onUpload }) => {
  const [uploading, setUploading] = React.useState(false);
  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    } else if (info.file.status === 'done') {
      onUpload?.(info.file.response);
      void message.success(`File uploaded`);
    } else if (info.file.status === 'error') {
      void message.error(`Error`);
    }

    setUploading(false);
  };
  return (
    <Upload
      name="file"
      onChange={handleUploadChange}
      action={`/api/files`}
      showUploadList={false}
      disabled={uploading}
    >
      <Button>
        {uploading ? <LoadingOutlined /> : <UploadOutlined />} Upload a file
      </Button>
    </Upload>
  );
};
