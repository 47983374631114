import {
  HolderOutlined,
  ProfileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { IActionItem } from '@shared/meetings';
import { UserMapItem } from '@shared/types';
import { complimentaryColor } from '@web/app/styles/ColorStyles';
import { StopPropagation } from '@web/components/StopPropagation';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Small, Text, TypographyCss } from '@web/components/typography';
import { Avatar, Checkbox, Tooltip } from 'antd';
import { formatDistance } from 'date-fns';
import humanparser from 'humanparser';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const ActionItemRow: React.FC<{
  actionItem: IActionItem;
  onResolve: (taskItem: IActionItem, resolved: boolean) => void;
  userViewing?: UserMapItem;
  sortable?: boolean;
  showAgendaItem?: boolean;
  linkTo?: string;
}> = ({
  actionItem,
  onResolve,
  userViewing,
  sortable = false,
  showAgendaItem = false,
  linkTo,
}) => {
  const navigate = useNavigate();
  const [resolved, setResolved] = React.useState(!!actionItem.resolvedDate);
  const handleCheckboxClicked = async () => {
    const newResolved = !resolved;
    setResolved(newResolved);
    onResolve(actionItem, newResolved);
  };
  React.useEffect(() => {
    setResolved(!!actionItem.resolvedDate);
  }, [actionItem.resolvedDate]);

  const highlightColour = complimentaryColor.lighten(0.9);
  const userViewingName = userViewing
    ? humanparser.parseName(userViewing.name).firstName
    : '';

  return (
    <Tooltip
      title={`${userViewingName} is viewing this`}
      open={userViewing ? undefined : false}
      trigger={['hover']}
      placement="topRight"
    >
      <SortableRow
        gap={6}
        style={{
          background: userViewing ? highlightColour : undefined,
          borderRadius: userViewing ? 0 : 8,
          padding: userViewing ? '6px 27px 6px 24px' : '6px 6px 6px 0px',
          margin: userViewing ? '0 -24px' : 0,
          position: 'relative',
        }}
      >
        <Row>
          {sortable && (
            <RowItem>
              <DragAnchor className="drag-anchor" />
            </RowItem>
          )}
          <RowItem>
            <StopPropagation>
              <Checkbox checked={resolved} onChange={handleCheckboxClicked} />
            </StopPropagation>
          </RowItem>
        </Row>
        <Spacer size={1} />
        {actionItem.assignedUser ? (
          <Tooltip title={`Assigned to ${actionItem.assignedUser.name}`}>
            <UserAvatar user={actionItem.assignedUser} size={24} />{' '}
          </Tooltip>
        ) : (
          <Tooltip title={`Not assigned`}>
            <Avatar size={24} icon={<UserOutlined />} />
          </Tooltip>
        )}
        <Column>
          <RowItem
            style={{ flex: 1, cursor: linkTo ? 'pointer' : 'inherit' }}
            onClick={
              linkTo
                ? () => {
                    navigate(linkTo);
                  }
                : undefined
            }
          >
            <ItemLinkText
              style={resolved ? { textDecoration: 'line-through' } : undefined}
            >
              <span>{actionItem.title} </span>
            </ItemLinkText>
          </RowItem>
          {showAgendaItem && actionItem.agendaItem && (
            <AgendaItemLink
              to={`/meetings/${actionItem.meetingToken}/agenda-items/${actionItem.agendaItemToken}`}
            >
              <ProfileOutlined />
              {actionItem.agendaItem.title}
            </AgendaItemLink>
          )}
        </Column>
        <GrowingSpacer />
        {showAgendaItem && (
          <Small>
            {formatDistance(new Date(), new Date(actionItem.createdDate))} ago
          </Small>
        )}
      </SortableRow>
    </Tooltip>
  );
};
const AgendaItemLink = styled(Link)`
  ${TypographyCss.Small}
  color: #aaa;
  display: flex;
  gap: 6px;

  &:hover {
    text-decoration: underline;
  }
`;
const ItemLinkText = styled(Text)`
  line-height: 18px;
  margin-top: 2px;

  &:hover {
    text-decoration: underline;
  }
`;
export const RowItem = styled.div`
  gap: 0 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
export const DragAnchor = styled(HolderOutlined)`
  opacity: 0;
  font-size: 18px;
  color: #888;
  cursor: grab;
`;
export const NewRow = styled(Row)`
  align-items: flex-start;
  margin: 2px 0 8px 0;
`;
const SortableRow = styled(NewRow)`
  min-height: 24px;

  &:hover {
    ${DragAnchor} {
      opacity: 1;
      cursor: grab;

      &:hover {
        color: #333;
      }
    }
  }
`;
