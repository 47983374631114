import { IAgendaItem, IMeeting } from '@shared/meetings';
import { post } from '@web/common/api';
import { Spacer } from '@web/components/layout';
import { Input, InputRef, Modal, message } from 'antd';
import * as React from 'react';

interface Props {
  onClose: () => void;
  onAdd: (agendaItem: IAgendaItem) => void;
  meeting?: IMeeting;
}

export const AddAgendaItemModal: React.FC<Props> = ({
  onClose,
  onAdd,
  meeting,
}) => {
  const titleRef = React.useRef<InputRef>();
  const [title, setTitle] = React.useState('');
  React.useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, [titleRef.current]);

  const handleUpdateClicked = async () => {
    try {
      const agendaItem = await post<IAgendaItem>(
        `/meetings/${meeting.token}/agenda_items`,
        {
          title,
        },
      );
      setTitle('');
      void message.success('Success');
      onAdd(agendaItem);
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Modal
      title="Add to Agenda"
      open={true}
      onOk={handleUpdateClicked}
      onCancel={onClose}
      okButtonProps={{ disabled: title.length === 0 }}
      width={500}
      okText="Add New"
    >
      <Spacer size={20} />
      <Input
        ref={titleRef}
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && title?.length > 0) {
            void handleUpdateClicked();
          }
        }}
      />
      <Spacer size={20} />
    </Modal>
  );
};
