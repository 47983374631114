import { IAgendaItem } from '@shared/meetings';
import { UserRole } from '@shared/types';
import { useAuth } from '@web/auth/useAuth';
import { CommentsList } from '@web/comments/CommentsList';
import { del, put } from '@web/common/api';
import { formatDate } from '@web/common/formatDate';
import { Button } from '@web/components/Button';
import { Markdown } from '@web/components/Markdown';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header1, SubduedText, Text } from '@web/components/typography';
import { Divider, message } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { AgendaItemAttachments } from './AgendaItemAttachments';
import { EditAgendaItemDetails } from './EditAgendaItemDetails';
import { ActionItemList } from './action_items/ActionItemsList';

export const AgendaItemDetails: React.FC<{
  agendaItem?: IAgendaItem;
  onChange: (agendaItem: IAgendaItem) => void;
  height: React.CSSProperties['height'];
}> = ({ agendaItem, onChange, height }) => {
  const { user } = useAuth();
  const [editing, setEditing] = React.useState(false);
  const canEdit = [UserRole.PRESIDENT, UserRole.PROPERTY_MANAGER].includes(
    user.role,
  );
  const handleResolve = async (resolve = true) => {
    try {
      if (resolve) {
        await put(
          `/meetings/${agendaItem.meetingToken}/agenda_items/${agendaItem.token}/resolved`,
        );
      } else {
        await del(
          `/meetings/${agendaItem.meetingToken}/agenda_items/${agendaItem.token}/resolved`,
        );
      }
      void message.success('Success');
      onChange(agendaItem);
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleEdit = () => {
    setEditing(true);
  };

  if (editing) {
    return (
      <EditAgendaItemDetails
        agendaItem={agendaItem}
        onChange={() => {
          onChange(agendaItem);
          setEditing(false);
        }}
        onCancel={() => {
          setEditing(false);
        }}
      />
    );
  }

  // const items: MenuProps['items'] = [
  //   {
  //     key: 'attachment',
  //     label: 'Add an attachment',
  //     icon: <UploadOutlined />,
  //   },
  //   {
  //     key: 'action',
  //     label: 'Add an action item',
  //     icon: <CheckSquareOutlined />,
  //   },
  // ];

  return (
    <AgendaItemDetailsContainer style={{ height }}>
      <Column>
        <Header1
          style={{
            lineHeight: '24px',
            textDecoration: agendaItem.resolvedDate ? 'line-through' : 'unset',
          }}
        >
          {agendaItem.title}
        </Header1>
        <Row gap={6}>
          {agendaItem.resolvedDate ? (
            <SubduedText>
              Closed {formatDate(agendaItem.resolvedDate)}
            </SubduedText>
          ) : (
            <SubduedText>
              Created {formatDate(agendaItem.createdDate)}
            </SubduedText>
          )}
          <GrowingSpacer />
          {/* {canEdit && (
            <Dropdown menu={{ items }}>
              <Button>
                <PlusOutlined />
              </Button>
            </Dropdown>
          )} */}
          {canEdit && (
            <Button
              onClick={() => {
                handleEdit();
              }}
            >
              Edit
            </Button>
          )}
          <Button
            onClick={() => {
              void handleResolve(!agendaItem.resolvedDate);
            }}
          >
            {agendaItem.resolvedDate ? 'Reopen' : 'Close'}
          </Button>
        </Row>
        <Spacer size={12} />
        {agendaItem.description ? (
          <Markdown value={agendaItem.description} />
        ) : (
          <Text style={{ fontStyle: 'italic' }}>No description</Text>
        )}
      </Column>
      <AgendaItemAttachments
        agendaItem={agendaItem}
        onChange={() => {
          onChange(agendaItem);
        }}
        readonly={!canEdit}
      />
      <Divider />
      <ActionItemList agendaItem={agendaItem} title="Action Items" />
      <Divider />
      <CommentsList
        entityToken={agendaItem.token}
        showReply
        placeholder="Add some notes"
      />
    </AgendaItemDetailsContainer>
  );
};

const AgendaItemDetailsContainer = styled(Column)`
  padding: 32px 32px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 2;
  gap: 12px;
  overflow-x: hidden;
  border-right: 1px solid #eee;
  height: calc(100vh - 50px);
  overflow-y: auto;
`;
