import { useAuth } from '@web/auth/useAuth';
import * as React from 'react';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { EmojiSelectorProvider } from './EmojiSelectorProvider';
import { Page } from './Page';
import { OrganizationFilterProvider } from './organizationFilter';
import { StylesProvider } from './styles/StylesProvider';

export const App: FC = () => {
  const { isLoading } = useAuth();
  if (isLoading) {
    return null;
  }

  return (
    <BrowserRouter>
      <StylesProvider>
        <OrganizationFilterProvider>
          <EmojiSelectorProvider>
            <Page>
              <AppRoutes />
            </Page>
          </EmojiSelectorProvider>
        </OrganizationFilterProvider>
      </StylesProvider>
    </BrowserRouter>
  );
};
