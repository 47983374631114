import { UserMapItem } from '@shared/types';
import { UserAvatar } from '@web/components/UserAvatar';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import * as React from 'react';

export const UserTag: React.FC<{ user: UserMapItem }> = ({ user }) => {
  return (
    <Row gap={6}>
      <UserAvatar user={user} size={16} />
      <Text>{user.name}</Text>
    </Row>
  );
};
