import { useMediaQuery } from 'react-responsive';
import { useMatch } from 'react-router-dom';

const Breakpoints = {
  desktop: 1230,
  laptop: 1024,
};

export const SHORT_VIEWPORT_MEDIA_QUERY = {
  query: `(max-height: 800px) and (min-width: ${Breakpoints.laptop}px)`,
};

export const DESKTOP_MEDIA_QUERY = {
  query: `(min-width: ${Breakpoints.desktop}px)`,
};

export const LAPTOP_MEDIA_QUERY = {
  query: `(min-width: ${Breakpoints.laptop}px) and (max-width: ${
    Breakpoints.desktop - 1
  }px)`,
};

export const NOT_DESKTOP_MEDIA_QUERY = {
  query: `(max-width: ${Breakpoints.desktop - 1}px)`,
};

export const MOBILE_MEDIA_QUERY = {
  query: `(max-width: ${Breakpoints.laptop - 1}px)`,
};

export const NOT_MOBILE_MEDIA_QUERY = {
  query: `(min-width: ${Breakpoints.laptop}px)`,
};

export const useResponsive = () => {
  const isLoginPage = useMatch('/auth/login');
  const isMeetingPage = useMatch('/meetings/:meetingToken*');
  const isRoot = useMatch('/');
  const isFullPageView = isLoginPage || isRoot || !!isMeetingPage;

  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const isLaptop = useMediaQuery(LAPTOP_MEDIA_QUERY);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const isShortViewport = useMediaQuery(SHORT_VIEWPORT_MEDIA_QUERY);

  return {
    isDesktop,
    isLaptop,
    isMobile,
    isFullPageView,
    isShortViewport,
  };
};

export const IF_DESKTOP = `@media only screen and ${DESKTOP_MEDIA_QUERY.query}`;
export const IF_NOT_DESKTOP = `@media only screen and ${NOT_DESKTOP_MEDIA_QUERY.query}`;
export const IF_LAPTOP = `@media only screen and ${LAPTOP_MEDIA_QUERY.query}`;
export const IF_MOBILE = `@media only screen and ${MOBILE_MEDIA_QUERY.query}`;
export const IF_SHORT_VIEWPORT = `@media only screen and ${MOBILE_MEDIA_QUERY.query}`;
export const IF_NOT_MOBILE = `@media only screen and ${NOT_MOBILE_MEDIA_QUERY.query}`;
