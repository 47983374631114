import { IUser, UserMapItem } from '@shared/types';
import { useApi } from '@web/common/useApi';
import { Select } from 'antd';
import { stringifyUrl } from 'query-string/base';
import * as React from 'react';

import { UserTag } from '../UserTag';

interface Props {
  disabled?: boolean;
  value: UserMapItem;
  onChange?: (newValue: UserMapItem) => void;
  style?: React.CSSProperties;
  placeholder?: string;
}

export const SimpleSelectUser: React.FC<Props> = ({
  disabled,
  onChange,
  style,
  placeholder,
  value,
}) => {
  const userSearchUrl = stringifyUrl({
    url: '/users/select/search',
    query: {
      limit: 20,
    },
  });
  const { data: users } = useApi<IUser[]>(userSearchUrl);
  const handleSelectChange = (userToken: any) => {
    const user = users?.find((u) => u.token === userToken);
    onChange(user);
  };

  return (
    <Select
      value={value.token}
      placeholder={placeholder}
      disabled={disabled}
      style={style}
      onChange={handleSelectChange as any}
    >
      {users?.map((user) => (
        <Select.Option key={user.token} value={user.token}>
          <UserTag user={user} />
        </Select.Option>
      )) ?? null}
    </Select>
  );
};
