import { ArrowLeftOutlined } from '@ant-design/icons';
import { IF_NOT_MOBILE, useResponsive } from '@web/app/responsive';
import { Typography } from 'antd';
import React from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';

import { Column, GrowingSpacer, Row, Spacer } from './layout';
import { Subheading } from './text';

export const Pane = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  background: white;
  gap: ${(props) => props.gap ?? 0}px;

  ${IF_NOT_MOBILE} {
    padding: 24px;
    border-radius: var(--default-border-radius);
    border: 1px solid var(--pane-border-color);
  }
`;

export const PaneClickable = styled(Pane)`
  cursor: pointer;
  box-shadow: none;
  border: 1px solid #eee;

  &:hover {
    border: 1px solid #ccc;
    box-shadow: var(--hover-drop-shadow);
  }
`;

export const PaneHeader: React.FC<{
  extras?: React.ReactNode;
  title: React.ReactNode;
  backTo?: string;
  description?: React.ReactNode;
  navigateBack?: boolean;
  defaultNavigateBackTo?: string;
}> = ({
  extras,
  title,
  backTo,
  description,
  navigateBack,
  defaultNavigateBackTo,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useResponsive();
  const [searchParams] = useSearchParams();
  const effectiveBackTo = searchParams.get('backTo') ?? backTo;
  const canGoBack = history.length > 1 && location.key !== 'default';

  return (
    <>
      <Row style={{ alignItems: 'flex-start' }}>
        {backTo && (
          <Link
            to={effectiveBackTo}
            style={{
              marginRight: 10,
              marginTop: 2,
              color: 'var(--color-text)',
              cursor: 'pointer',
            }}
          >
            <ArrowLeftOutlined />
          </Link>
        )}
        {navigateBack && canGoBack && (
          <BackButton onClick={() => navigate(-1)}>
            <ArrowLeftOutlined />
          </BackButton>
        )}
        {navigateBack && !canGoBack && defaultNavigateBackTo && (
          <BackButton
            onClick={() => navigate(defaultNavigateBackTo, { replace: true })}
          >
            <ArrowLeftOutlined />
          </BackButton>
        )}
        <Column style={{ width: '100%' }}>
          <Row>
            <Subheading>{title}</Subheading>
            <GrowingSpacer min={10} />
            {extras}
          </Row>
          {description && (
            <Typography.Text style={{ marginTop: 0, marginLeft: 2 }}>
              {description}
            </Typography.Text>
          )}
        </Column>
      </Row>
      <Spacer size={isMobile ? 10 : 20} />
    </>
  );
};

const BackButton = styled.button`
  appearance: none;
  padding-left: 0;
  color: var(--color-text);
  border: 0;
  cursor: pointer;
  background: transparent;
`;
