import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { PrimaryButton } from '@web/components/Button';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header1, Text } from '@web/components/typography';
import { Divider, Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { TopicListItem } from './TopicListItem';

export const TopicsPage: React.FC = () => {
  const { data: topics } = useApi('/topics');
  if (!topics) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  return (
    <PageContent>
      <Column>
        <Row gap={12}>
          <Header1 style={{ whiteSpace: 'nowrap' }}>Board Topics</Header1>
          <GrowingSpacer />
          <Link to={`/topics/new`}>
            <PrimaryButton>Add Topic</PrimaryButton>
          </Link>
        </Row>
        <Divider />
        {topics.length > 0 ? (
          topics.map((topic, index) => (
            <>
              {index !== 0 && <Divider />}
              <TopicListItem topic={topic} key={topic.token} />
            </>
          ))
        ) : (
          <Text>No topics exists</Text>
        )}
      </Column>
    </PageContent>
  );
};
