import { EditOutlined } from '@ant-design/icons';
import { UserMapItem } from '@shared/types';
import { formatDate } from '@web/common/formatDate';
import { InvisibleButton } from '@web/components/InvisibleButton';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import * as React from 'react';

import { Header2, Text, TypographyColors } from './typography';

export const UserMessage: React.FC<{
  user: UserMapItem;
  children?: React.ReactNode;
  date?: Date | string;
  large?: boolean;
  avatarSize?: number;
  onEdit?: () => void;
  fontSize?: number;
  style?: React.CSSProperties;
  tag?: React.ReactNode;
  includeTime?: boolean;
}> = ({
  user,
  date,
  children,
  large,
  onEdit,
  style = {},
  avatarSize = 32,
  fontSize = 14,
  tag,
  includeTime,
}) => {
  return (
    <Row gap={12} style={{ ...style, alignItems: 'flex-start', width: '100%' }}>
      <UserAvatar user={user} size={avatarSize} />
      <Column style={{ width: '100%' }}>
        <Row>
          <Header2
            style={{
              fontWeight: 400,
              lineHeight: `${avatarSize}px`,
              fontSize: large ? 18 : fontSize,
            }}
          >
            {user.name}
          </Header2>
          {tag && (
            <>
              <Spacer size={12} />
              {tag}
            </>
          )}
          {onEdit && (
            <InvisibleButton
              onClick={onEdit}
              style={{ marginLeft: 4, maxHeight: avatarSize }}
            >
              <EditOutlined
                style={{ fontSize: 11, color: TypographyColors.text }}
              />
            </InvisibleButton>
          )}
          <GrowingSpacer />
          {date && (
            <Text style={{ color: '#888', fontSize }}>
              {formatDate(date, includeTime)}
            </Text>
          )}
        </Row>
        {children && <Column style={{ width: '100%' }}>{children}</Column>}
      </Column>
    </Row>
  );
};
