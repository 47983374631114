import { InputComment } from '@web/comments/InputComment';
import { post } from '@web/common/api';
import { message } from 'antd';
import * as React from 'react';

export const AddEditComment: React.FC<{
  entityToken: string;
  onAdd: () => void;
  placeholder?: string;
}> = ({ entityToken, onAdd, placeholder }) => {
  const handleSubmit = async (text: string) => {
    try {
      await post(`/comments`, { entityToken, text });
      onAdd();
    } catch (error) {
      void message.error('Error');
    }
  };

  return <InputComment onSubmit={handleSubmit} placeholder={placeholder} />;
};
