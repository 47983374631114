import { IMeeting } from '@shared/meetings';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { EditMeeting } from './EditMeeting';

export const NewMeetingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleNewMeeting = (meeting: IMeeting) => {
    navigate(`/meetings/${meeting.token}`, { replace: true });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageContent>
      <PageHeader title="New Meeting" navigateBack />
      <EditMeeting onSave={handleNewMeeting} onCancel={handleCancel} />
    </PageContent>
  );
};
