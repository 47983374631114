import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { ITopic } from '@shared/topics';
import { useAuth } from '@web/auth/useAuth';
import { Button } from '@web/components/Button';
import { Column, Row } from '@web/components/layout';
import { Header2, Text } from '@web/components/typography';
import { Tooltip, message } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { Upload, UploadFile } from 'antd/lib';
import * as React from 'react';

import { FileButton } from '../admin/FileButton';

export const TopicAttachments: React.FC<{
  onChange: () => void;
  topic: ITopic;
  readonly?: boolean;
}> = ({ topic, onChange, readonly = false }) => {
  const { user } = useAuth();
  const [uploading, setUploading] = React.useState(false);
  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    } else if (info.file.status === 'done') {
      onChange();
      void message.success(`Success`);
    } else if (info.file.status === 'error') {
      void message.error(`Error`);
    }

    setUploading(false);
  };
  const isOwner = user.token === topic.ownerToken;

  return (
    <Column gap={12}>
      <Header2>Attachments</Header2>
      <Row gap={6} style={{ flexWrap: 'wrap' }}>
        {!topic.files || topic.files.length === 0 ? (
          <Text>None</Text>
        ) : (
          topic.files.map((file) => <FileButton file={file} key={file.token} />)
        )}
        {isOwner && !readonly && (
          <Tooltip title="Add an attachment to this topic" trigger={['hover']}>
            <Upload
              name="file"
              onChange={handleUploadChange}
              action={`/api/topics/${topic.token}/attachments`}
              showUploadList={false}
              disabled={uploading}
            >
              <Button>
                {uploading ? <LoadingOutlined /> : <UploadOutlined />} Add
              </Button>
            </Upload>
          </Tooltip>
        )}
      </Row>
    </Column>
  );
};
