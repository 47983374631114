import { LOGIN_REDIRECT_PATH_KEY } from '@shared/constants';
import { useOrganizationFilter } from '@web/app/organizationFilter';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from './useAuth';

export const LoginRedirect: React.FC = () => {
  const navigate = useNavigate();
  const { isSuper } = useAuth();
  const { organizationTokenFilter } = useOrganizationFilter();

  React.useEffect(() => {
    const loginRedirectPath = localStorage.getItem(LOGIN_REDIRECT_PATH_KEY);
    let redirectTo;
    if (
      loginRedirectPath?.length > 0 &&
      loginRedirectPath !== '/auth/login' &&
      loginRedirectPath !== '/onboarding'
    ) {
      redirectTo = loginRedirectPath;
      localStorage.setItem(LOGIN_REDIRECT_PATH_KEY, '');
    } else if (isSuper) {
      redirectTo = organizationTokenFilter
        ? `/organizations/${organizationTokenFilter}/users`
        : '/user/settings';
    } else {
      redirectTo = '/home';
    }
    // Unsure why this setTimeout was needed for internal users hitting /redirect
    // redirectTo path is computed correctly by navigate() fails without setTimeout
    setTimeout(() => {
      navigate(redirectTo, { replace: true });
    }, 0);
  }, []);

  return null;
};
