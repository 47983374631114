"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cssinjs = require("@ant-design/cssinjs");
var _multiple = require("../../select/style/multiple");
var _internal = require("../../theme/internal");
const genSize = (token, suffix) => {
  const {
    componentCls,
    controlHeight
  } = token;
  const suffixCls = suffix ? `${componentCls}-${suffix}` : '';
  const multipleSelectorUnit = (0, _multiple.getMultipleSelectorUnit)(token);
  return [
  // genSelectionStyle(token, suffix),
  {
    [`${componentCls}-multiple${suffixCls}`]: {
      paddingBlock: multipleSelectorUnit.containerPadding,
      paddingInlineStart: multipleSelectorUnit.basePadding,
      minHeight: controlHeight,
      // ======================== Selections ========================
      [`${componentCls}-selection-item`]: {
        height: multipleSelectorUnit.itemHeight,
        lineHeight: (0, _cssinjs.unit)(multipleSelectorUnit.itemLineHeight)
      }
    }
  }];
};
const genPickerMultipleStyle = token => {
  const {
    componentCls,
    calc,
    lineWidth
  } = token;
  const smallToken = (0, _internal.mergeToken)(token, {
    fontHeight: token.fontSize,
    selectHeight: token.controlHeightSM,
    multipleSelectItemHeight: token.multipleItemHeightSM,
    borderRadius: token.borderRadiusSM,
    borderRadiusSM: token.borderRadiusXS,
    controlHeight: token.controlHeightSM
  });
  const largeToken = (0, _internal.mergeToken)(token, {
    fontHeight: calc(token.multipleItemHeightLG).sub(calc(lineWidth).mul(2).equal()).equal(),
    fontSize: token.fontSizeLG,
    selectHeight: token.controlHeightLG,
    multipleSelectItemHeight: token.multipleItemHeightLG,
    borderRadius: token.borderRadiusLG,
    borderRadiusSM: token.borderRadius,
    controlHeight: token.controlHeightLG
  });
  return [
  // ======================== Size ========================
  genSize(smallToken, 'small'), genSize(token), genSize(largeToken, 'large'),
  // ====================== Selection ======================
  {
    [`${componentCls}${componentCls}-multiple`]: Object.assign(Object.assign({
      width: '100%',
      // ==================== Selector =====================
      [`${componentCls}-selector`]: {
        flex: 'auto',
        padding: 0,
        '&:after': {
          margin: 0
        }
      }
    }, (0, _multiple.genOverflowStyle)(token)), {
      // ====================== Input ======================
      // Input is `readonly`, which is used for a11y only
      [`${componentCls}-multiple-input`]: {
        width: 0,
        height: 0,
        border: 0,
        visibility: 'hidden',
        position: 'absolute',
        zIndex: -1
      }
    })
  }];
};
var _default = exports.default = genPickerMultipleStyle;