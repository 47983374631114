import {
  AgendaItemToken,
  IAgendaItem,
  IMeeting,
  MeetingToken,
} from '@shared/meetings';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { Grid } from '@web/components/layout';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MeetingInfo } from './MeetingInfo';
import { AgendaItemDetails } from './agenda_items/AgendaItemDetails';
import { AgendaItemsList } from './agenda_items/AgendaItemsList';

export const MeetingPlanningPage: React.FC = () => {
  const navigate = useNavigate();
  const { meetingToken, agendaItemToken } = useParams<{
    meetingToken?: MeetingToken;
    agendaItemToken?: AgendaItemToken;
  }>();
  const { data: meeting, mutate: reloadMeeting } = useApi<IMeeting>(
    `/meetings/${meetingToken}`,
  );

  // React.useEffect(() => {
  //   if (meeting?.agendaItems?.length > 0 && !selectedAgendaItem) {
  //     setSelectedAgendaItem(minBy(meeting.agendaItems, 'position'));
  //   }
  // }, [meeting]);

  if (!meeting) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const handleSelectAgendaItem = (agendaItem: IAgendaItem | null) => {
    if (agendaItem) {
      navigate(`/meetings/${meeting.token}/agenda-items/${agendaItem.token}`);
    } else {
      navigate(`/meetings/${meeting.token}`);
    }
  };

  const handleAgendaItemAdded = async (agendaItem: IAgendaItem) => {
    await reloadMeeting();
    navigate(`/meetings/${meeting.token}/agenda-items/${agendaItem.token}`);
  };

  const handleMeetingClosed = () => {
    navigate('/meetings');
  };

  const selectedAgendaItem = meeting.agendaItems.find(
    (agendaItem) => agendaItem.token === agendaItemToken,
  );

  return (
    <PageContent style={{ padding: 0 }}>
      <Grid columns="2fr 3fr" style={{ width: '100%' }}>
        <AgendaItemsList
          meeting={meeting}
          onAdd={handleAgendaItemAdded}
          onSelect={handleSelectAgendaItem}
          selectedAgendaItem={selectedAgendaItem}
          height="calc(100vh - 50px)"
        />
        {selectedAgendaItem ? (
          <AgendaItemDetails
            key={selectedAgendaItem.token}
            agendaItem={selectedAgendaItem}
            onChange={() => {
              void reloadMeeting();
            }}
            height="calc(100vh - 50px)"
          />
        ) : (
          <MeetingInfo
            meeting={meeting}
            onAdd={handleAgendaItemAdded}
            onClose={handleMeetingClosed}
          />
        )}
      </Grid>
    </PageContent>
  );
};
