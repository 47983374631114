import { useAuth } from '@web/auth/useAuth';
import * as React from 'react';
import styled from 'styled-components';

import { ImpersonationBanner } from './ImpersonationBanner';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { IF_MOBILE, useResponsive } from './responsive';

export interface IPageProps {
  children: React.ReactNode;
}

export const PageContent: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => (
  <PageContentContainer style={style}>{children}</PageContentContainer>
);

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 24px;

  ${IF_MOBILE} {
    padding: 0;
  }
`;

export const Page: React.FC<IPageProps> = ({ children }) => {
  const { isLoggedIn, isSuper } = useAuth();
  const { isFullPageView, isMobile } = useResponsive();

  if (isSuper) {
    return (
      <PageContainer>
        <Layout>
          <RightSide showingSidebar={true}>{children}</RightSide>
        </Layout>
        <Sidebar width={SIDEBAR_WIDTH} />
      </PageContainer>
    );
  }

  const padding = isMobile ? 0 : isFullPageView ? 0 : 24;
  const maxWidth = isFullPageView ? 'unset' : 1024;

  return (
    <PageContainer>
      {isLoggedIn && <Topbar />}
      <Layout>
        <CenteredContent style={{ padding, maxWidth }}>
          {children}
        </CenteredContent>
        <ImpersonationBanner />
      </Layout>
    </PageContainer>
  );
};

export const SIDEBAR_WIDTH = 240;

const PageContainer = styled.div``;

const Layout = styled.div`
  display: flex;
  position: relative;

  > :first-child {
    min-width: ${SIDEBAR_WIDTH}px;
  }
`;

const RightSide = styled.section<{ showingSidebar: boolean }>`
  ${(props) =>
    props.showingSidebar
      ? `
    margin-left: ${SIDEBAR_WIDTH}px;
  `
      : 'margin-left: 0px;'}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--page-background);
  flex: 1;
`;

const CenteredContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--page-background);
  flex: 1;
  margin: 0 auto;
`;
