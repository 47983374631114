import { IComment } from '@shared/comments';
import { ISearchResults } from '@shared/types';
import { get } from '@web/common/api';
import { UserAvatar } from '@web/components/UserAvatar';
import { Column, Row } from '@web/components/layout';
import * as React from 'react';

import { AddEditComment } from './AddEditComment';
import { ViewEditComment } from './ViewEditComment';

interface Props {
  entityToken: string;
  commentsSearchResult?: ISearchResults<IComment>;
  onChange?: () => void;
  readonly?: boolean;
  showReply: boolean;
  placeholder: string;
}

export const CommentsList: React.FC<Props> = ({
  entityToken,
  commentsSearchResult,
  readonly = false,
  showReply,
  placeholder,
}) => {
  const [searchResults, setSearchResults] =
    React.useState(commentsSearchResult);
  const fetchComments = async () => {
    const results = await get<ISearchResults<IComment>>(
      `/comments/${entityToken}`,
    );
    setSearchResults(results);
  };
  React.useEffect(() => {
    if (!commentsSearchResult) {
      void fetchComments();
    }
  }, []);

  const { results: comments, userMap } = searchResults ?? {};
  const hasComments = comments?.length > 0;

  if (!hasComments && readonly) {
    return null;
  }

  return (
    <Column gap={24}>
      {hasComments ? (
        <Column gap={12}>
          {comments?.map((comment) => (
            <Column key={comment.token}>
              <Row gap={12} style={{ alignItems: 'flex-start' }}>
                <UserAvatar size={24} user={userMap[comment.authorToken]} />
                <ViewEditComment
                  comment={comment}
                  onChange={() => {
                    void fetchComments();
                  }}
                />
              </Row>
            </Column>
          ))}
        </Column>
      ) : null}
      {!readonly && showReply && (
        <AddEditComment
          entityToken={entityToken}
          onAdd={() => {
            void fetchComments();
          }}
          placeholder={placeholder}
        />
      )}
    </Column>
  );
};
