import { IOrganization } from '@shared/organizations';
import { post } from '@web/common/api';
import { Form, Input, Modal, message } from 'antd';
import React from 'react';

interface Props {
  onCancel: () => void;
  onAdd: (organization: IOrganization) => void;
  open: boolean;
}

export const AddOrganizationModal: React.FC<Props> = ({
  onAdd,
  onCancel,
  open,
}) => {
  const [form] = Form.useForm<IOrganization>();
  const [isSaving, setIsSaving] = React.useState(false);

  const handleOk = async () => {
    setIsSaving(true);
    let organization: IOrganization;
    try {
      organization = await form.validateFields();
    } catch (error) {
      // ignore validation errors and rely on inline error messages
      setIsSaving(false);
      return;
    }

    try {
      const savedUser = await post<IOrganization>(
        '/organizations',
        organization,
      );
      void message.success('Successfully added organization');
      form.resetFields();
      onAdd(savedUser);
    } catch (error) {
      console.error(error);
      void message.error('Failed to add organization');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setIsSaving(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Add Organization"
      open={open}
      onOk={() => {
        void handleOk();
      }}
      afterClose={handleClose}
      confirmLoading={isSaving}
      onCancel={onCancel}
      width="400px"
    >
      <Form form={form} name="basic" autoComplete="off" layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input disabled={isSaving} autoFocus />
        </Form.Item>
      </Form>
    </Modal>
  );
};
