import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { EditTopic } from './EditTopic';

export const EditTopicPage: React.FC = () => {
  const navigate = useNavigate();

  const handleUpdateTopic = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageContent>
      <PageHeader title="Edit Topic" navigateBack />
      <EditTopic onSave={handleUpdateTopic} onCancel={handleCancel} />
    </PageContent>
  );
};
