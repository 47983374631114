import { IUser, UserMapItem } from '@shared/types';
import { initials } from '@web/common/initials';
import { Avatar } from 'antd';
import * as React from 'react';
import stc from 'string-to-color';

export const UserAvatar: React.FC<{
  user?: IUser | UserMapItem;
  name?: string;
  style?: React.CSSProperties;
  size?: number;
  backgroundColor?: string;
}> = ({ user, name, style, backgroundColor, size = 48 }) => {
  const imageSrc = user?.avatarImageUrl;
  const displayName = user?.name ?? name;
  return (
    <Avatar
      size={size}
      icon={imageSrc ? <img src={imageSrc} /> : null}
      style={{
        flexGrow: 0,
        flexShrink: 0,
        backgroundColor: backgroundColor ?? stc(user?.name ?? name),
        border: 0,
        fontSize: Math.max(12, Math.round((size * 3) / 8)),
        ...style,
      }}
    >
      {!imageSrc && displayName ? initials(displayName) : '-'}
    </Avatar>
  );
};
