import { IFile } from './files';
import { OrganizationToken } from './organizations';
import { IUser, UserToken } from './types';

export type TopicToken = `t_${string}`;
export interface ITopic {
  token: TopicToken;
  title: string;
  content?: string;
  ownerToken: UserToken;
  owner?: IUser;
  files?: IFile[];
  organizationToken?: OrganizationToken;
  state: TopicState;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}
export enum TopicState {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}
