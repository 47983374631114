import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { EditMeeting } from './EditMeeting';

export const EditMeetingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleUpdateMeeting = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <PageContent>
      <PageHeader title="Edit Meeting" navigateBack />
      <EditMeeting onSave={handleUpdateMeeting} onCancel={handleCancel} />
    </PageContent>
  );
};
