import { OrganizationToken } from '@shared/organizations';
import { PageContent } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { NotificationsTable } from './NotificationsTable';

export const NotificationsPage: React.FC = () => {
  const { organizationToken } = useParams<{
    organizationToken: OrganizationToken;
  }>();

  return (
    <PageContent>
      <PageHeader title="Notifications" />
      <NotificationsTable organizationToken={organizationToken} />
    </PageContent>
  );
};
