import { IMeeting, MeetingToken } from '@shared/meetings';
import { ScheduledEventUpdate } from '@shared/scheduled_event';
import { patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { useScheduledEvent } from '@web/common/useScheduledEvent';
import { SelectDateTime } from '@web/components/SelectDateTime';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Skeleton, message } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  onSave?: (meeting: IMeeting) => void;
  onCancel?: () => void;
  onClose?: () => void;
}
export const EditMeeting: React.FC<Props> = ({ onSave, onCancel, onClose }) => {
  const { confirm, contextHolder } = useModalConfirm();
  const { meetingToken } = useParams<{ meetingToken: MeetingToken }>();
  const { data: meeting } = useApi<IMeeting | undefined>(
    meetingToken ? `/meetings/${meetingToken}` : null,
  );
  const isEdit = !!meetingToken;
  const [isSaving, setIsSaving] = React.useState(false);
  const dateEvent = useScheduledEvent({ hour: 18 });
  React.useEffect(() => {
    if (meeting?.date) {
      dateEvent.setAll(meeting.date);
    }
  }, [meeting]);

  if (isEdit && !meeting) {
    return <Skeleton />;
  }

  const handleSave = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      let savedMeeting;
      if (isEdit) {
        savedMeeting = await patch<{ date: ScheduledEventUpdate }, IMeeting>(
          `/meetings/${meetingToken}`,
          {
            date: dateEvent.getEntity(),
          },
        );
      } else {
        savedMeeting = await post<{ date: ScheduledEventUpdate }, IMeeting>(
          `/meetings`,
          {
            date: dateEvent.getEntity(),
          },
        );
      }
      void message.success('Success');
      onSave?.(savedMeeting);
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = async () => {
    const confirmed = await confirm('Do you wish to close this meeting?');
    if (!confirmed) {
      return;
    }

    try {
      await post(`/meetings/${meeting.token}/close`);
      onClose?.();
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column gap={24}>
      <FormElement>
        <Text>Date</Text>
        <SelectDateTime
          date={dateEvent.date}
          hour={dateEvent.hour}
          onChange={(date, hour) => {
            dateEvent.setDate(date);
            dateEvent.setHour(hour);
          }}
        />
      </FormElement>
      <Row gap={6}>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        {onClose && <Button onClick={handleClose}>Close</Button>}
      </Row>
      {contextHolder}
    </Column>
  );
};

const FormElement = styled(Column)`
  gap: 12px;
  max-width: 600px;
`;
