const lightTheme = "_light-theme_1xkrx_1";
const darkTheme = "_dark-theme_1xkrx_1";
const bold = "_bold_1xkrx_10";
const italic = "_italic_1xkrx_14";
const underline = "_underline_1xkrx_18";
const strikethrough = "_strikethrough_1xkrx_34";
const underlineStrikethrough = "_underlineStrikethrough_1xkrx_38";
const subscript = "_subscript_1xkrx_42";
const superscript = "_superscript_1xkrx_47";
const code = "_code_1xkrx_52";
const nestedListItem = "_nestedListItem_1xkrx_59";
const listitem = "_listitem_1xkrx_69";
const listItemChecked = "_listItemChecked_1xkrx_73";
const listItemUnchecked = "_listItemUnchecked_1xkrx_74";
const admonitionDanger = "_admonitionDanger_1xkrx_151";
const admonitionInfo = "_admonitionInfo_1xkrx_152";
const admonitionNote = "_admonitionNote_1xkrx_153";
const admonitionTip = "_admonitionTip_1xkrx_154";
const admonitionCaution = "_admonitionCaution_1xkrx_155";
const styles = {
  lightTheme,
  darkTheme,
  bold,
  italic,
  underline,
  strikethrough,
  underlineStrikethrough,
  subscript,
  superscript,
  code,
  nestedListItem,
  listitem,
  listItemChecked,
  listItemUnchecked,
  admonitionDanger,
  admonitionInfo,
  admonitionNote,
  admonitionTip,
  admonitionCaution
};
export {
  admonitionCaution,
  admonitionDanger,
  admonitionInfo,
  admonitionNote,
  admonitionTip,
  bold,
  code,
  darkTheme,
  styles as default,
  italic,
  lightTheme,
  listItemChecked,
  listItemUnchecked,
  listitem,
  nestedListItem,
  strikethrough,
  subscript,
  superscript,
  underline,
  underlineStrikethrough
};
