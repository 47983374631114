import { IF_MOBILE } from '@web/app/responsive';
import styled from 'styled-components';

export const Grid = styled.div<{ gap?: number; columns?: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.columns ?? '1fr 1fr'};
  gap: ${(props) => props.gap || 0}px;
`;

export const Row = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap || 0}px;
`;

export const WrapRow = styled.div<{ gap?: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.gap || 0}px;
`;

export const ResponsiveRow = styled(Row)`
  ${IF_MOBILE} {
    flex-direction: column;
  }
`;

export const Column = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || 0}px;
`;

export const Spacer = styled.div<{ size?: number }>`
  width: ${(props) => props.size ?? 24}px;
  height: ${(props) => props.size ?? 24}px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const GrowingSpacer = styled.div<{ min?: number }>`
  flex: 1;
  ${(props) =>
    props.min > 0
      ? `
    min-height: ${props.min}px;
    min-width: ${props.min}px;
  `
      : ''};
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const NoWrap = styled.div`
  white-space: nowrap;
`;

export const Max = styled.div<{ width: string }>`
  max-width: ${(props) => props.width};
`;

export const Absolute = styled.div<{
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}>`
  position: absolute;
  ${(props) => (props.top !== undefined ? `top: ${props.top}px;` : '')}
  ${(props) => (props.right !== undefined ? `right: ${props.right}px;` : '')}
  ${(props) => (props.left !== undefined ? `left: ${props.left}px;` : '')}
  ${(props) => (props.bottom !== undefined ? `bottom: ${props.bottom}px;` : '')}
`;

export const Relative = styled.div`
  position: relative;
`;
