import { IFile } from './files';
import { OrganizationToken } from './organizations';
import { IScheduledEvent } from './scheduled_event';
import { ITopic, TopicToken } from './topics';
import { IUser, UserToken } from './types';

export type MeetingToken = `m_${string}`;
export interface IMeeting {
  token: MeetingToken;
  organizationToken?: OrganizationToken;
  state: MeetingState;
  date: IScheduledEvent;
  agendaItems?: IAgendaItem[];
  agenda: AgendaItemToken[];
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}
export enum MeetingState {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export type ActionItemToken = `act_${string}`;
export interface IActionItem {
  token: ActionItemToken;
  title: string;
  assignedUserToken?: UserToken;
  assignedUser?: IUser;
  meetingToken?: MeetingToken;
  agendaItemToken?: AgendaItemToken;
  agendaItem?: IAgendaItem;
  organizationToken?: OrganizationToken;
  resolvedDate?: Date;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}
export type AgendaItemToken = `ai_${string}`;
export interface IAgendaItem {
  token: AgendaItemToken;
  title: string;
  description: string;
  meetingToken?: MeetingToken;
  topicToken?: TopicToken;
  topic?: ITopic;
  files: IFile[];
  attachments: IFile[];
  labels: AgendaLabel[];
  sortedActionItems: ActionItemToken[];
  organizationToken?: OrganizationToken;
  resolvedDate?: Date;
  indexedDate?: Date;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}
export const isAgendaItemToken = (token: string): token is AgendaItemToken =>
  token.startsWith('ai_');

export enum AgendaLabel {
  INFO = 'INFO',
  DISCUSS = 'DISCUSS',
  APPROVAL = 'APPROVAL',
}
