import { IMeeting } from '@shared/meetings';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { useApi } from '@web/common/useApi';
import { Spacer } from '@web/components/layout';
import {
  Header1,
  Header2,
  Text,
  TypographyCss,
} from '@web/components/typography';
import { Row } from 'antd';
import { sortBy } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

export const ViewAgendaPage: React.FC = () => {
  const { organization } = useAuth();
  const { meetingToken } = useParams();
  const { data: meeting } = useApi<IMeeting>(`/meetings/${meetingToken}`);

  if (!meeting) {
    return null;
  }

  const agendaItems = sortBy(meeting.agendaItems, 'position');
  return (
    <PageContent>
      <Header2>Board of Directors Meeting Agenda</Header2>
      <Header1>{organization.name}</Header1>
      <Row>
        <Label>Date:</Label>
        <Text>{meeting?.date ? meeting.date?.date : 'N/A'}</Text>
      </Row>
      <Spacer size={24} />
      <AgendaItemList>
        {agendaItems.map((agendaItem) => (
          <AgendaItemListItem key={agendaItem.token}>
            {agendaItem.description}
          </AgendaItemListItem>
        ))}
      </AgendaItemList>
    </PageContent>
  );
};

const Label = styled(Text)`
  width: 60px;
  font-weight: bold;
`;

const AgendaItemList = styled.ol``;

const AgendaItemListItem = styled.li`
  ${TypographyCss.Header3}
  padding-left: 12px;
  line-height: 32px;
`;
