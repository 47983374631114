import { IActionItem } from '@shared/meetings';
import { UserMapItem } from '@shared/types';
import { del, patch } from '@web/common/api';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { InlineInput } from '@web/components/InlineInput';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { SelectUser } from '@web/components/users/SelectUser';
import { Checkbox, Drawer, message } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { DrawerTitle } from './DrawerTitle';

export const ActionItemDrawer: React.FC<{
  actionItem: IActionItem;
  onClose: () => void;
  onChange?: () => void;
}> = ({ actionItem, onClose, onChange }) => {
  const { confirm, contextHolder } = useModalConfirm();
  const [originalTitle, setOriginalTitle] = React.useState(
    actionItem.title ?? '',
  );
  const [title, setTitle] = React.useState(actionItem.title ?? '');
  const [assignedUser, setAssignedUser] = React.useState<UserMapItem>(
    actionItem.assignedUser ?? null,
  );

  const resolved = !!actionItem.resolvedDate;
  const handleSaveTitle = async () => {
    if (!title || title === originalTitle) {
      return;
    }

    try {
      await patch<IActionItem>(`/action_items/${actionItem.token}`, { title });
      setOriginalTitle(title);
      onChange?.();
    } catch (error) {
      void message.error('Error');
    }
  };
  const handleAssignedUserChanged = async (user: UserMapItem) => {
    const originalAssignedUser = assignedUser;
    try {
      setAssignedUser(user);
      await patch<IActionItem>(`/action_items/${actionItem.token}`, {
        assignedUserToken: user.token,
      });
      setOriginalTitle(title);
      onChange?.();
    } catch (error) {
      void message.error('Error');
      setAssignedUser(originalAssignedUser);
    }
  };

  const handleClose = async () => {
    await handleSaveTitle();
    onClose();
  };

  const toggleResolved = async () => {
    try {
      if (!resolved) {
        await patch<IActionItem>(`/action_items/${actionItem.token}`, {
          resolvedDate: new Date(),
        });
      } else {
        await patch<IActionItem>(`/action_items/${actionItem.token}`, {
          resolvedDate: null,
        });
      }
      onChange?.();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleDelete = async () => {
    const confirmed = await confirm(
      'Did you mean to resolve this action item instead? Removing this action item will remove all associated comments and is irreversible.',
      { title: 'Remove Action Item' },
    );
    if (!confirmed) {
      return;
    }

    try {
      await del(`/action_items/${actionItem.token}`);
      onChange?.();
      void handleClose();
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleEnterPressed = async () => {
    changeFocus();
  };

  const handleTitleBlur = async () => {
    await handleSaveTitle();
    changeFocus();
  };

  const changeFocus = () => {
    const closeButton: HTMLButtonElement =
      document.querySelector('.ant-drawer-close');
    closeButton?.focus();
  };

  return (
    <Drawer
      title={<DrawerTitle onDelete={handleDelete} />}
      placement="right"
      onClose={handleClose}
      open={true}
      width={500}
      styles={{ body: { overflow: 'hidden', paddingTop: 0 } }}
    >
      <TitleContainer>
        <Row gap={6} style={{ alignItems: 'flex-start', paddingTop: 6 }}>
          <Checkbox checked={resolved} onClick={toggleResolved} />
          <InlineInput
            value={title}
            onChange={setTitle}
            onEnter={handleEnterPressed}
            onBlur={handleTitleBlur}
            placeholder="Enter action"
            style={{
              fontSize: 18,
              lineHeight: '22px',
              marginTop: -5,
              textDecoration: actionItem.resolvedDate
                ? 'line-through'
                : undefined,
            }}
          />
        </Row>
      </TitleContainer>
      <Spacer />
      <Row gap={12}>
        <Text>Assigned to</Text>
        <SelectUser
          initialUser={assignedUser}
          onChange={(_, user) => {
            void handleAssignedUserChanged(user);
          }}
          style={{ flex: 1 }}
        />
      </Row>
      {contextHolder}
    </Drawer>
  );
};

const TitleContainer = styled(Column)`
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
`;
