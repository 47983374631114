import { SyncOutlined } from '@ant-design/icons';
import { INotification, NotificationStatus } from '@shared/notitifications';
import { post } from '@web/common/api';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { message } from 'antd';
import * as React from 'react';

export const ResendNotificationButton: React.FC<{
  notification: INotification;
  onChange?: () => void;
}> = ({ notification, onChange }) => {
  if (notification.resentBy) {
    return null;
  }

  const handleResend = async (notificationToken: INotification['token']) => {
    try {
      const resentNotification = await post<INotification>(
        `/email/notifications/${notificationToken}/resend`,
      );
      if (resentNotification.status === NotificationStatus.SENT) {
        void message.success('Success');
      } else if (resentNotification.status === NotificationStatus.ERROR) {
        void message.error(resentNotification.error);
      } else {
        void message.error(`Bad status: ${resentNotification.status}`);
      }
    } catch (error) {
      void message.error(error.message ?? 'Failed');
    } finally {
      onChange?.();
    }
  };

  return (
    <ConfirmButton
      title="Resend notification"
      description="Are you sure you wish to resend this notification?"
      okText="Continue"
      onConfirm={() => {
        void handleResend(notification.token);
      }}
      size="small"
    >
      <SyncOutlined style={{ position: 'relative', top: -1 }} />
    </ConfirmButton>
  );
};
