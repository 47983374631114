import { Divider } from 'antd';
import styled from 'styled-components';

export const DateDivider = styled(Divider).attrs({})`
  &.ant-divider-horizontal.ant-divider-with-text {
    margin: 6px 0;
  }

  .ant-divider-inner-text {
    font-size: 13px;
    font-weight: 400;
    border: 1px solid #ddd;
    border-radius: 20px;
    color: #888;
  }

  &::before,
  &::after {
    background: #f5f5f5;
  }
`;
