"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cssinjs = require("@ant-design/cssinjs");
var _colorBlock = require("./color-block");
const genPickerStyle = token => {
  const {
    componentCls,
    controlHeightLG,
    borderRadiusSM,
    colorPickerInsetShadow,
    marginSM,
    colorBgElevated,
    colorFillSecondary,
    lineWidthBold,
    colorPickerHandlerSize,
    colorPickerHandlerSizeSM,
    colorPickerSliderHeight
  } = token;
  return {
    [`${componentCls}-select`]: {
      [`${componentCls}-palette`]: {
        minHeight: token.calc(controlHeightLG).mul(4).equal(),
        overflow: 'hidden',
        borderRadius: borderRadiusSM
      },
      [`${componentCls}-saturation`]: {
        position: 'absolute',
        borderRadius: 'inherit',
        boxShadow: colorPickerInsetShadow,
        inset: 0
      },
      marginBottom: marginSM
    },
    [`${componentCls}-handler`]: {
      width: colorPickerHandlerSize,
      height: colorPickerHandlerSize,
      border: `${(0, _cssinjs.unit)(lineWidthBold)} solid ${colorBgElevated}`,
      position: 'relative',
      borderRadius: '50%',
      cursor: 'pointer',
      boxShadow: `${colorPickerInsetShadow}, 0 0 0 1px ${colorFillSecondary}`,
      '&-sm': {
        width: colorPickerHandlerSizeSM,
        height: colorPickerHandlerSizeSM
      }
    },
    [`${componentCls}-slider`]: {
      borderRadius: token.calc(colorPickerSliderHeight).div(2).equal(),
      [`${componentCls}-palette`]: {
        height: colorPickerSliderHeight
      },
      [`${componentCls}-gradient`]: {
        borderRadius: token.calc(colorPickerSliderHeight).div(2).equal(),
        boxShadow: colorPickerInsetShadow
      },
      '&-alpha': (0, _colorBlock.getTransBg)(`${(0, _cssinjs.unit)(colorPickerSliderHeight)}`, token.colorFillSecondary),
      '&-hue': {
        marginBottom: marginSM
      }
    },
    [`${componentCls}-slider-container`]: {
      display: 'flex',
      gap: marginSM,
      marginBottom: marginSM,
      [`${componentCls}-slider-group`]: {
        flex: 1,
        '&-disabled-alpha': {
          display: 'flex',
          alignItems: 'center',
          [`${componentCls}-slider`]: {
            flex: 1,
            marginBottom: 0
          }
        }
      }
    }
  };
};
var _default = exports.default = genPickerStyle;