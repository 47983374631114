/* eslint-disable no-template-curly-in-string */
import { UserMapItem } from '@shared/types';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, Row } from '@web/components/layout';
import { Button, Input, Select, Typography, message } from 'antd';
import * as React from 'react';

export const SendNotificationTemplate: React.FC<{ user: UserMapItem }> = ({
  user,
}) => {
  const [templateToken, setTemplateToken] = React.useState(null);
  const [placeholderValues, setPlaceholderValues] = React.useState({});

  const { data: notificationTemplates } = useApi(`/notifications/templates`);
  if (!notificationTemplates) {
    return null;
  }

  const validatePlaceholders = () => {
    for (const placeholder of placeholders) {
      if (!placeholderValues[placeholder]) {
        void message.error(
          `Missing placholder value for ${placeholder.slice(2, -1)}`,
        );
        return false;
      }
    }

    return true;
  };

  const handlePreview = async () => {
    if (!validatePlaceholders()) {
      return;
    }

    try {
      await post('/slack/send-preview', {
        notificationTemplateToken: templateToken,
        userToken: user.token,
        placeholderValues,
      });
      void message.success('Preview sent');
    } catch (error) {
      console.log(error);
      void message.error('Error');
    }
  };

  const handleSend = async () => {
    if (!validatePlaceholders()) {
      return;
    }

    try {
      await post('/slack/send-notification', {
        notificationTemplateToken: templateToken,
        userToken: user.token,
        placeholderValues,
      });
      void message.success('Message sent');
    } catch (error) {
      console.log(error);
      void message.error('Error');
    }
  };

  const selectedTemplate = notificationTemplates.find(
    (template) => template.token === templateToken,
  );
  let placeholders: string[] =
    selectedTemplate?.blocks.match(/\$\{[a-zA-Z]+\}/g) ?? [];
  placeholders = placeholders.filter(
    (placholder) => !BUILTIN_PLACEHOLDERS.includes(placholder),
  );

  return (
    <Pane>
      <Column gap={24} style={{ alignItems: 'flex-start' }}>
        <UserMessage user={user} />
        <Typography.Text>Select a notification</Typography.Text>
        <Select style={{ width: 500 }} onChange={setTemplateToken}>
          {notificationTemplates.map((template) => (
            <Select.Option value={template.token} key={template.token}>
              {template.type}
            </Select.Option>
          ))}
        </Select>
        {placeholders?.map((placeholder) => (
          <Row key={placeholder}>
            <Typography.Text style={{ width: 150 }}>
              {placeholder.slice(2, -1)}
            </Typography.Text>
            <Input
              value={placeholderValues[placeholder]}
              disabled={BUILTIN_PLACEHOLDERS.includes(placeholder)}
              onChange={(e) => {
                setPlaceholderValues((values) => ({
                  ...values,
                  [placeholder]: e.target.value,
                }));
              }}
            />
          </Row>
        ))}
        <Row gap={10}>
          <ConfirmButton
            disabled={!templateToken}
            onConfirm={handleSend}
            title="Please confirm"
            description={`Do you wish to send this Slack notification to ${user.name}?`}
            okText="Send"
            type="primary"
          >
            Send Notification
          </ConfirmButton>
          <Button
            disabled={!templateToken}
            type="default"
            onClick={handlePreview}
          >
            Send Preview
          </Button>
        </Row>
      </Column>
    </Pane>
  );
};

const BUILTIN_PLACEHOLDERS = ['${name}', '${condoUrl}'];
