import { SearchOutlined } from '@ant-design/icons';
import { IOrganization } from '@shared/organizations';
import { ISearchResults } from '@shared/types';
import { useOrganizationFilter } from '@web/app/organizationFilter';
import { get } from '@web/common/api';
import { Column, Row } from '@web/components/layout';
import { Input } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { debounce } from 'lodash';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { AddOrganizationButton } from './AddOrganizationButton';
import { SelectOrganization } from './SelectOrganization';

const PAGE_SIZE = 6;

interface Props {
  onClose: () => void;
  open: boolean;
}
const OrganizationsModal: React.FC<Props> = ({ onClose, open }) => {
  const navigate = useNavigate();
  const { setOrganizationTokenFilter } = useOrganizationFilter();
  const [page, setPage] = React.useState(1);
  const [query, setQuery] = React.useState('');
  const [results, setResults] = React.useState<ISearchResults<IOrganization>>({
    results: [],
    total: 0,
  });
  const { results: organizations, total } = results;

  const searchOrganizations = async () => {
    const newResults = await get<ISearchResults<IOrganization>>(
      `/organizations?skip=${
        (page - 1) * PAGE_SIZE
      }&limit=${PAGE_SIZE}&query=${query}`,
    );
    setResults(newResults);
  };
  const debouncedSearch = debounce(searchOrganizations, 300);

  React.useEffect(() => {
    void debouncedSearch();
  }, [page, query]);

  const handleoOrganizationAdded = () => {
    void searchOrganizations();
  };

  const handleQueryChanged = (newQuery: string) => {
    setQuery(newQuery);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSelect = (organization: IOrganization) => {
    setOrganizationTokenFilter(organization.token);
    onClose();
  };

  const handleView = (organization: IOrganization) => {
    navigate(`/organizations/${organization.token}/details`);
    onClose();
  };

  const handleCancel = () => {
    onClose();
    setQuery('');
    setPage(1);
  };

  return (
    <Modal
      title="Select an organization"
      open={open}
      onCancel={handleCancel}
      width="800px"
      footer={null}
    >
      <Column gap={12}>
        <Row gap={12}>
          <Input
            placeholder="Search for an organization"
            prefix={<SearchOutlined />}
            allowClear
            value={query}
            onChange={(e) => {
              handleQueryChanged(e.currentTarget.value);
            }}
          />
          <AddOrganizationButton onAdd={handleoOrganizationAdded} />
        </Row>
        <SelectOrganization
          organizations={organizations}
          total={total}
          page={page}
          pageSize={PAGE_SIZE}
          onPageChange={handlePageChange}
          onSelect={handleSelect}
          onView={handleView}
        />
      </Column>
    </Modal>
  );
};

export default OrganizationsModal;
