import { AgendaItemToken } from './meetings';
import { OrganizationToken } from './organizations';
import { TopicToken } from './topics';
import { IUser, UserToken } from './types';

export type FileToken = `f_${string}`;
export interface IFile {
  token: FileToken;
  name: string;
  description?: string;
  contentType: string;
  content?: Buffer;
  ownerToken: UserToken;
  owner?: IUser;
  topicToken?: TopicToken;
  agendaItemToken?: AgendaItemToken;
  organizationToken?: OrganizationToken;
  estimateToken?: EstimateToken;
  indexedDate?: Date;
  scannedDate?: Date;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}
export const isFile = (file: any): file is IFile =>
  file?.token.startsWith('f_');
export const isFileToken = (token: string): token is FileToken =>
  token.startsWith('f_');

export type EstimateToken = `f_${string}`;
export interface IEstimate {
  token: EstimateToken;
  id?: string;
  date?: string;
  totalCost?: number;
  company?: IContact;
  salesContact?: IContact;
  descriptionOfWork?: string;
  client?: IContact;
  notes?: string;
}

export type ContactToken = `ct_${string}`;
export interface IContact {
  name: string;
  address: string;
  phone: string;
  email: string;
  type: 'company' | 'individual';
}
