import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { clamp } from 'lodash';
import * as React from 'react';
import { Portal } from 'react-portal';
import styled from 'styled-components';

export interface IEmojiSelectorContextValue {
  selectEmoji: (currentEl: HTMLElement) => Promise<string | null>;
  cancelSelect: () => void;
}

export const EmojiSelectorContext =
  React.createContext<IEmojiSelectorContextValue>({
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    selectEmoji: () => Promise.resolve(null),
    cancelSelect: () => {},
  });

export const useEmojiSelector = () => React.useContext(EmojiSelectorContext);

export const EmojiSelectorProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const PICKER_WIDTH = 352;
  const PICKER_HEIGHT = 435;

  const [selectEmojiResolve, setSelectEmojiResolve] = React.useState<{
    resolve: (emoji: string) => void;
  }>(null);
  const [pickerPosition, setPickerPosition] = React.useState<{
    top: number;
    left: number;
  }>(null);
  const pickerContainerRef = React.useRef(null);

  const closePicker = () => {
    if (pickerPosition) {
      setPickerPosition(null);
    }
  };

  const handleEmojiSelect = ({ native }: { native: string }) => {
    closePicker();
    if (selectEmojiResolve) {
      selectEmojiResolve.resolve(native);
    }
  };

  const contextValue: IEmojiSelectorContextValue = React.useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/promise-function-async
      selectEmoji(currentEl: HTMLElement): Promise<string | null> {
        const currentClientRect = currentEl.getBoundingClientRect();
        const isOnBottomHalf = currentClientRect.top > window.innerHeight / 2;
        const top = isOnBottomHalf
          ? currentClientRect.top + window.scrollY - 20 - PICKER_HEIGHT
          : currentClientRect.top + window.scrollY + 40;
        const left =
          currentClientRect.left +
          Math.round(currentClientRect.width / 2) +
          window.scrollX -
          PICKER_WIDTH / 2;

        // this needs to be execute off of the current thread
        // because if the current thread is a click event,
        // that click event will be handled by the Picker below and
        // will close itself immediately upon opening
        setTimeout(() => {
          setPickerPosition({
            top: clamp(
              top,
              20 + window.scrollY,
              window.innerHeight + window.scrollY - PICKER_HEIGHT - 20,
            ),
            left: clamp(
              left,
              20 + window.scrollX,
              window.innerWidth + window.scrollX - PICKER_WIDTH - 30,
            ),
          });
        }, 0);

        return new Promise<string>((resolve) => {
          setSelectEmojiResolve({ resolve });
        });
      },
      cancelSelect: () => {
        closePicker();
      },
    }),
    [],
  );

  return (
    <EmojiSelectorContext.Provider value={contextValue}>
      {children}
      <Portal>
        <PickerContainer
          style={
            pickerPosition
              ? { ...pickerPosition, opacity: 1 }
              : { left: -PICKER_WIDTH, top: 0, opacity: 0 }
          }
          ref={pickerContainerRef}
        >
          {pickerPosition && (
            <Picker
              data={data}
              onEmojiSelect={handleEmojiSelect}
              onClickOutside={closePicker}
            />
          )}
        </PickerContainer>
      </Portal>
    </EmojiSelectorContext.Provider>
  );
};

const PickerContainer = styled.div`
  position: absolute;
  transition: opacity 0.2s linear;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;
