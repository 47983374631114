import { FileToken } from '@shared/files';
import { IAgendaItem } from '@shared/meetings';
import { FileButton } from '@web/admin/FileButton';
import { del } from '@web/common/api';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { Button } from '@web/components/Button';
import { Column } from '@web/components/layout';
import { message } from 'antd';
import { sortBy } from 'lodash';
import * as React from 'react';

import { AddAttachmentModal } from './AddAttachmentModal';

export const AgendaItemAttachments: React.FC<{
  onChange: () => void;
  agendaItem: IAgendaItem;
  readonly?: boolean;
}> = ({ agendaItem, onChange, readonly = false }) => {
  const { confirm, contextHolder } = useModalConfirm();
  const [showAddModal, setShowAddModal] = React.useState(false);
  const handleRemoveAttachment = async (fileToken: FileToken) => {
    const confirmed = await confirm(
      'This will remove this attachment from this agenda item. Do you wish to continue?',
    );
    if (!confirmed) {
      return;
    }

    try {
      await del(
        `/meetings/agenda_items/${agendaItem.token}/attachments/${fileToken}`,
      );
      void message.success('Attachment removed');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleAdded = () => {
    setShowAddModal(false);
    onChange();
  };

  const hasFiles = agendaItem.attachments && agendaItem.attachments.length > 0;

  if (!hasFiles && readonly) {
    return null;
  }

  const sortedFiles = hasFiles ? sortBy(agendaItem.attachments, 'name') : [];
  return (
    <Column gap={12}>
      <Column gap={12}>
        {!hasFiles
          ? null
          : sortedFiles.map((file) => (
              <FileButton
                file={file}
                key={file.token}
                onRemove={() => {
                  void handleRemoveAttachment(file.token);
                }}
                onChange={() => {
                  onChange();
                }}
              />
            ))}
        {!readonly && (
          <Button
            onClick={() => {
              setShowAddModal(true);
            }}
          >
            Add Attachment
          </Button>
        )}
      </Column>
      {contextHolder}
      {showAddModal && (
        <AddAttachmentModal
          agendaItemToken={agendaItem.token}
          onClose={() => {
            setShowAddModal(false);
          }}
          onAdd={handleAdded}
        />
      )}
    </Column>
  );
};
