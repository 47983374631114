import { PageContext } from '@web/app/PageContext';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useBackNavigation = (
  navigateBack: boolean,
  defaultNavigateBackTo?: string,
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const canGoBack = history.length > 1 && location.key !== 'default';
  let goBack: () => void;
  if (navigateBack) {
    if (canGoBack) {
      goBack = () => navigate(-1);
    } else if (defaultNavigateBackTo) {
      goBack = () => navigate(defaultNavigateBackTo, { replace: true });
    }
  }

  return { canGoBack, goBack };
};

export const usePageDetails = () => {
  const {
    pageDetails: {
      title,
      navigateBack,
      defaultNavigateBackTo,
      primaryAction,
      secondaryActions,
    },
  } = React.useContext(PageContext);
  const { goBack } = useBackNavigation(
    navigateBack ?? false,
    defaultNavigateBackTo,
  );
  const showMobileNavBar = !goBack;
  return { goBack, title, showMobileNavBar, primaryAction, secondaryActions };
};
