import { NotificationsPage } from '@web/notifications/NotificationsPage';
import UserActivityPage from '@web/users/UserPage/UserActivityPage';
import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { EditUserPage, OrganizationPage, UsersPage } from '../lazy';

export const internalRoutes = () => (
  <Route path="organizations">
    <Route path=":organizationToken">
      <Route index element={<Navigate to="details" replace />} />
      <Route path="details" element={<OrganizationPage />} />
      <Route path="users">
        <Route index element={<UsersPage />} />
        <Route path=":userToken" element={<EditUserPage />} />
        <Route path=":userToken/activity" element={<UserActivityPage />} />
      </Route>
      <Route path="notifications">
        <Route index element={<NotificationsPage />} />
      </Route>
    </Route>
  </Route>
);
