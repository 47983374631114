import {
  CloseOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { FileToken, IFile } from '@shared/files';
import { AgendaItemToken } from '@shared/meetings';
import { SearchDropdown } from '@web/app/SearchDropdown';
import { patch, post } from '@web/common/api';
import { Button } from '@web/components/Button';
import { Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { FileIcon } from '@web/topics/FileIcon';
import { Input, Modal, Select, message } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { Upload, UploadFile } from 'antd/lib';
import { last } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

enum AttachmentType {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export const AddAttachmentModal: React.FC<{
  agendaItemToken: AgendaItemToken;
  onAdd: (fileAdded: IFile) => void;
  onClose: () => void;
}> = ({ agendaItemToken, onClose, onAdd }) => {
  const [attachmentType, setAttachmentType] = React.useState<AttachmentType>(
    AttachmentType.NEW,
  );
  const [file, setFile] = React.useState<IFile>(null);
  const [description, setDescription] = React.useState('');

  const handleOkClicked = async () => {
    const canSubmit =
      attachmentType === AttachmentType.NEW ? !!file && !!description : !!file;
    if (!canSubmit) {
      return;
    }

    try {
      if (attachmentType === AttachmentType.NEW) {
        await patch<IFile>(`/files/${file.token}`, {
          description,
        });
      }

      const addedFile = await post<{ fileToken: FileToken }, IFile>(
        `/meetings/agenda_items/${agendaItemToken}/attachments`,
        {
          fileToken: file.token,
        },
      );
      onAdd(addedFile);

      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Modal
      title="Add Attachment"
      open={true}
      onOk={handleOkClicked}
      onCancel={onClose}
      okButtonProps={{
        disabled:
          attachmentType === AttachmentType.NEW ? !file || !description : !file,
      }}
      width={500}
      okText="Attach"
    >
      <Spacer size={12} />
      <Select
        style={{ width: '100%' }}
        value={attachmentType}
        onChange={setAttachmentType}
      >
        <Select.Option value={AttachmentType.NEW}>
          Upload new file
        </Select.Option>
        <Select.Option value={AttachmentType.EXISTING}>
          Attach existing file
        </Select.Option>
      </Select>
      <Spacer size={24} />
      {attachmentType === AttachmentType.NEW ? (
        <UploadNewFile
          file={file}
          setFile={setFile}
          description={description}
          setDescription={setDescription}
        />
      ) : (
        <AttachExistingFile file={file} setFile={setFile} />
      )}
      <Spacer />
    </Modal>
  );
};

const UploadNewFile: React.FC<{
  description: string;
  setDescription: (newDescription: string) => void;
  file: IFile;
  setFile: (newFile: IFile) => void;
}> = ({ description, setDescription, file, setFile }) => {
  const [uploading, setUploading] = React.useState(false);
  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    } else if (info.file.status === 'done') {
      setFile(info.file.response);
    } else if (info.file.status === 'error') {
      void message.error(`Error`);
    }

    setUploading(false);
  };
  const extension = file ? last(file.name.split('.')) : null;
  return (
    <>
      <Text>Description</Text>
      <Input
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <Spacer size={24} />
      <Text>File</Text>
      {file ? (
        <UploadedFile>
          <FileIcon extension={extension} />
          <Text>{file.name}</Text>
          <CloseOutlined onClick={() => setFile(null)} />
        </UploadedFile>
      ) : (
        <Upload
          name="file"
          onChange={handleUploadChange}
          action={`/api/files`}
          showUploadList={false}
          disabled={uploading}
        >
          <Button>
            {uploading ? <LoadingOutlined /> : <UploadOutlined />} Upload a file
          </Button>
        </Upload>
      )}
    </>
  );
};

const AttachExistingFile: React.FC<{
  file: IFile;
  setFile: (newFile: IFile) => void;
}> = ({ file, setFile }) => {
  if (file) {
    const extension = file ? last(file.name.split('.')) : null;
    return (
      <UploadedFile>
        <FileIcon extension={extension} />
        <Text>{file.name}</Text>
        <CloseOutlined onClick={() => setFile(null)} />
      </UploadedFile>
    );
  }

  return (
    <SearchDropdown
      placeholder="Search for an existing file"
      style={{ width: '100%' }}
      onSelect={(file) => {
        setFile(file);
      }}
    />
  );
};

const UploadedFile = styled(Row)`
  gap: 6px;

  svg {
    width: 14px;
    height: 14px;
  }
`;
