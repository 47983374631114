import { TablePaginationConfig } from 'antd';
import { pickBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';

export interface PaginationOptions {
  pageSize: number;
}

export const usePagination = (
  totalResults: number = 0,
  options: PaginationOptions = { pageSize: 10 },
) => {
  const { pageSize } = options;
  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
    query: '',
  });
  const setPage = (newPage: number) => {
    setSearchParams({ ...params, page: `${newPage}` });
  };
  const setQuery = (query: string) => {
    setSearchParams({ ...params, query });
  };
  const params = pickBy({
    page: searchParams.get('page'),
    query: searchParams.get('query'),
  });
  const page = parseInt(params.page ?? '1');
  const query = params.query ?? '';
  const pagination: TablePaginationConfig = {
    total: totalResults,
    onChange: setPage,
    current: page,
    pageSize,
    showSizeChanger: false,
    showTotal: (total) => `Total: ${total}`,
  };

  return { pagination, pageSize, page, query, setPage, setQuery };
};
