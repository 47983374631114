import { ITopic, TopicState } from '@shared/topics';
import { formatDate } from '@web/common/formatDate';
import { Row } from '@web/components/layout';
import { SubduedText, Text } from '@web/components/typography';
import { Tag } from 'antd';
import * as React from 'react';

import { UserTag } from '../components/UserTag';

export const TopicInfo: React.FC<{ topic: ITopic }> = ({ topic }) => {
  return (
    <Row gap={12}>
      {topic.state === TopicState.OPEN ? (
        <Tag color="green">Open</Tag>
      ) : (
        <Tag>Closed</Tag>
      )}
      <UserTag user={topic.owner} />
      <Text>·</Text>
      {topic.state === TopicState.OPEN ? (
        <SubduedText>Opened {formatDate(topic.createdDate, true)}</SubduedText>
      ) : (
        <SubduedText>Closed {formatDate(topic.updatedDate, true)}</SubduedText>
      )}
    </Row>
  );
};
