import { InlineInput } from '@web/components/InlineInput';
import { Row } from '@web/components/layout';
import { Checkbox } from 'antd';
import * as React from 'react';

import { DragAnchor, NewRow, RowItem } from './ActionItemRow';

export const NewActionItem: React.FC<{
  onAdd: (text: string) => void;
  onHide: () => void;
}> = ({ onAdd, onHide }) => {
  const [text, setText] = React.useState<string | null>(null);

  const handleSave = async () => {
    if (!text) {
      onHide();
      return;
    }

    onAdd(text);
  };

  return (
    <NewRow gap={6}>
      <Row style={{ paddingTop: 4 }}>
        <RowItem>
          <DragAnchor style={{ opacity: 0 }} />
        </RowItem>
        <RowItem>
          <Checkbox />
        </RowItem>
      </Row>
      <InlineInput
        value={text}
        onChange={setText}
        placeholder="Add a description"
        onBlur={handleSave}
        autofocus
        style={{
          padding: '5px 6px',
          color: '#515c65',
          lineHeight: '18px',
          position: 'relative',
          left: 0,
        }}
      />
    </NewRow>
  );
};
