import { IAgendaItem, IMeeting, MeetingState } from '@shared/meetings';
import { setFromToken } from '@shared/setFromToken';
import { post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { Column, Row } from '@web/components/layout';
import { Header3, Text } from '@web/components/typography';
import { Button, Divider, message } from 'antd';
import * as React from 'react';

import { EditMeeting } from './EditMeeting';

export const MeetingInfo: React.FC<{
  onClose: () => void;
  meeting: IMeeting;
  onAdd: (agendaItem: IAgendaItem) => void;
}> = ({ onAdd, onClose, meeting }) => {
  const meetingClosed = meeting.state === MeetingState.CLOSED;
  const { data: unresolvedItems } = useApi<IAgendaItem[]>(
    !meetingClosed ? '/meetings/agenda_items/unresolved' : null,
  );

  const existingAgendaItems = setFromToken(meeting.agendaItems);
  const quickAddItems =
    unresolvedItems?.filter((item) => !existingAgendaItems.has(item.token)) ??
    [];

  const handleAddToAgenda = async (agendaItem: IAgendaItem) => {
    try {
      await post(`/meetings/${meeting.token}/agenda_items`, {
        token: agendaItem.token,
      });
      void onAdd(agendaItem);
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column
      style={{
        padding: 24,
        boxShadow: '0 0 10px rgba(0,0,0,0.3)',
        zIndex: 2,
      }}
      gap={24}
    >
      <EditMeeting onClose={onClose} />
      {!meetingClosed && (
        <>
          <Divider />
          <Header3>Add to Agenda</Header3>
          {quickAddItems.map((agendaItem) => (
            <Column gap={24} key={agendaItem.token}>
              <Row gap={24}>
                <Text>{agendaItem.title}</Text>
                <Button
                  onClick={() => {
                    void handleAddToAgenda(agendaItem);
                  }}
                  size="small"
                >
                  Add
                </Button>
              </Row>
            </Column>
          ))}
        </>
      )}
      {quickAddItems.length === 0 && <Text>No agenda items</Text>}
    </Column>
  );
};
